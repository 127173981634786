import Button from "components/atoms/Button/Button";
import Icon from "components/atoms/Icon/Icon";
import LeadScore from "components/atoms/LeadScore/LeadScore";
import Loading from "components/atoms/Loading/Loading";
import Tooltip from "components/atoms/Tooltip/Tooltip";
import Container from "components/atoms/layout/Container/Container";
import Skeleton from "components/atoms/layout/Skeleton/Skeleton";
import LeadHelper from "helpers/LeadHelper";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getContactRevealedByID, getLeadItem } from "store/slices/data.slice";
import { getLeadStats, getPixelsByPersons } from "store/slices/insights";
import { getPersonalInfo } from "store/slices/user.slice";
import EntryFormatter from "utils/EntryFormatter";
import constants from "utils/constants";
import { LeadProfileDTO } from "utils/dtos/leadDTO";
import { PixelDTO } from "utils/dtos/pixelDTO";
import { messages } from "utils/message";
import { secondsToHMS, showDate } from "utils/workWithData";

const CopyToClipboard: React.FC<{ text: string }> = ({ text }) => {
  return <Tooltip content="Copy Contact">
    <span className="cursor-pointer text-white-500"
      onClick={() => {
        navigator.clipboard.writeText(text);
        toast.success("Copied successfully", { toastId: "emailCopied" });
      }}>
      <Icon name="copy" size={17}/>
  </span></Tooltip>
}
const badgesClass = "flex items-center gap-2 text-sm text-gray-300";
const LeadViewModule: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const { leadId } = useParams();
  const [searchParams] = useSearchParams();
  const [lead, setLead] = useState<LeadProfileDTO | undefined>();
  const [pulses, setPulses] = useState<PixelDTO[]>([]);
  const [isStatsLoading, setIsStatsLoading] = useState(false);
  const navigate = useNavigate();
  const { isLoading: CRIsLoading, isSuccess: CRIsSuccess, data: CRData } = useAppSelector((state) =>state.data.contactsRevealed.find((x) => x.id === leadId)) ?? {};
  const [isLoading, setIsLoading] = useState(true);
  const [IsPulseListLoading, setIsPulseListLoading] = useState(true);
  const fetchLead = async () => {
    if (!leadId || !isLoading) {
      setIsLoading(false);
      return;
    }
    const res = await dispatch(getLeadItem(leadId));
    if (res.payload?.data) {
      setLead(res.payload.data);
      fetchLeadPulseStats();
      fetchLeadPulseList();
    }
    setIsLoading(false);
  };
  const fetchLeadPulseStats = async () => {
    setIsStatsLoading(true);
    const res = await dispatch(getLeadStats({ leadId: leadId! }));
    setIsStatsLoading(false);
    if (!res.payload?.data) return;
    const leadStats = res.payload.data;
    setLead((prev: any) => ({
      ...prev,
      visitLast: leadStats.visitLast,
      visits: leadStats.visits,
      visitors: leadStats.visitors,
      pageViews: leadStats.pages,
      duration: leadStats.duration,
      leadScore: leadStats.leadScore,
    }))
  }
  const fetchLeadPulseList = async () => {
    const res = await dispatch(getPixelsByPersons(leadId!));
    if (!EntryFormatter.isArrayEmpty(res.payload.data)) setPulses(res.payload.data);
    setIsPulseListLoading(false);
  };
  useEffect(() => { fetchLead(); }, []);
  useEffect(() => {
    if (!CRIsSuccess) return;
    if (CRData) {
      setLead((prev) => ({
        ...prev, ...CRData,
        visitLast: prev?.visitLast,
        visits: prev?.visits,
        visitors: prev?.visitors,
        pageViews: prev?.pageViews,
        duration: prev?.duration,
        leadScore: prev?.leadScore,
      }))
      dispatch(getPersonalInfo());
      if (LeadHelper.hasContactData(CRData)) toast.success(messages.ENRICHMENT_CONTACTREVEAL_SUCCESS, {toastId: "contactRevealed"});
      else toast.error(messages.ENRICHMENT_CONTACTREVEAL_CONTACT_MISSING);
    } else {
      toast.error(messages.ENRICHMENT_CONTACTREVEAL_FAILED);
    }
  }, [CRIsSuccess]);
  if (isLoading) return <Loading height="80vh" />;
  if (!lead) {
    // TODO: improve UI
    return <Container><div className="flex flex-col items-center justify-center text-white-500 text-lg gap-3 sm:pt-48">
      <Icon name="sad" size={60} />
      Lead not found
    </div></Container>
  }
  const UrlSearchParams = window.location.href?.split("?")?.[1];
  return <Container>
      <div className="breadcumb">
        {searchParams.get("app_source") === constants.PIXEL_KEYWORD ? (
          <p className="capitalize cursor-pointer"
            onClick={() => navigate(`/${constants.PIXEL_KEYWORD}${UrlSearchParams?.length > 0 ? `?${UrlSearchParams}` : ""}`)}
          >{constants.PIXEL_KEYWORD}</p>
        ) : (
          <p
            className="capitalize cursor-pointer"
            onClick={() =>
              navigate(
                `/${constants.LEADSEARCH_KEYWORD}${
                  UrlSearchParams?.length > 0 ? `?${UrlSearchParams}` : ""
                }`
              )
            }
          >
            {constants.LEADSEARCH_KEYWORD}
          </p>
        )}
        <div className="divider"><Icon name="chevron-right" size={10} /></div>
        <span>{lead?.nameFirst} {lead?.nameLast}</span>
      </div>
      <div className="flex sm:gap-9">
        <div className="flex items-center gap-5">
          <h1 className="font-bold text-2xl">{lead?.nameFirst} {lead?.nameLast}</h1>
          {lead.linkedInURL && <Tooltip content="LinkedIn Profile">
            <a href={EntryFormatter.formatLink(lead.linkedInURL)} target="_blank" className="text-sm text-white-500 hover:text-blue-500"><Icon name="linkedin" /></a>
          </Tooltip>}
        </div>
        <div className="flex items-center gap-6">
          {isStatsLoading ? <Skeleton width={48} height={32} /> : <Tooltip content="Lead Score"><LeadScore value={lead.leadScore || 0} /></Tooltip>}
          <Tooltip content="Visits"><div className={badgesClass}>
              <Icon name="mouse" size={21} />
              {isStatsLoading ? <Skeleton width={20} height={20} /> : lead.visits || 0}
          </div></Tooltip>
          <Tooltip content="Page Views"><div className={badgesClass}>
            <Icon name="page" size={20} />
            {isStatsLoading ? <Skeleton width={20} height={20} /> : lead.pageViews || 0}
          </div></Tooltip>
          <Tooltip content="Duration"><div className={badgesClass}>
            <Icon name="clock" size={18} />
            {isStatsLoading ? <Skeleton width={20} height={20} /> : secondsToHMS(lead.duration || 0)}
          </div></Tooltip>
        </div>
        {lead.hideContactInfo && <>
          {CRIsLoading
            ? <div><Loading height="auto" spinnerSize={28} /></div>
            : <Button size="sm" iconName="credits" iconSize={18} className="!h-[35px]"
                onClick={(e: any) => {
                  toast.success("Searching trillions of records in real-time. This may take a few seconds. We appreciate your patience!", { toastId: "fetchingContactRevealItem", autoClose: 10000 });
                  dispatch(getContactRevealedByID({ leadId: leadId! }));
                }}
              >Reveal Contact</Button>
          }
        </>}
      </div>
      <div className="flex sm:gap-6">
        <div className="basis-2/5 flex flex-col sm:gap-4">
          <div className="bg-gray-900 rounded-lg flex flex-col gap-4 py-4">
            <h2 className="font-bold text-lg px-4">Person Info</h2>
            <table className="table style-1"><tbody>
                <tr className="noBorder">
                  <td className="label">Business Email</td>
                  <td width="70%">{
                    lead.emailWork 
                      ? <div className="flex items-center gap-3">
                        <div className="badge green sm truncate">{lead.emailWork.email}</div> 
                        <CopyToClipboard text={lead.emailWork.email} />
                      </div>
                      : "-"
                  }</td>
                </tr>
                <tr className="noBorder">
                  <td className="label">Personal Email</td>
                  <td>{lead.emailPersonal 
                    ? <div className="flex items-center gap-3">
                      <div className="badge green sm truncate">{lead.emailPersonal.email}</div> 
                      <CopyToClipboard text={lead.emailPersonal.email} />
                    </div>
                    : "-"
                  }</td>
                </tr>
                <tr>
                  <td className="label">Alt Email</td>
                  <td>{lead.emailAlt 
                    ? <div className="flex items-center gap-3">
                        <div className="badge green sm truncate">{lead.emailAlt.email}</div> 
                        <CopyToClipboard text={lead.emailAlt.email} />
                      </div>
                    : "-"
                  }</td>
                </tr>
                <tr className="noBorder">
                  <td className="label">Phone</td>
                  <td>{lead.phone ? <div className="flex items-center gap-3">
                    <div className="badge green sm truncate">{lead.phone.number}</div> 
                    <CopyToClipboard text={lead.phone.number} />
                  </div> : "-"}</td>
                </tr>
                <tr>
                  <td className="label">Alt Phone</td>
                  <td>{lead.phoneAlt ? <div className="flex items-center gap-3">
                    <div className="badge green sm truncate">{lead.phoneAlt.number}</div> 
                    <CopyToClipboard text={lead.phoneAlt.number} />
                  </div> : "-"}</td>
                </tr>
                {/* ⭐ Address */}
                <tr className="noBorder">
                  <td className="label">Personal Address</td>
                  <td>{lead.address?.street || "-"}</td>
                </tr>
                <tr className="noBorder">
                  <td className="label">Personal City</td>
                  <td>{lead.address?.city || "-"}</td>
                </tr>
                <tr className="noBorder">
                  <td className="label">Personal State</td>
                  <td>{lead.address?.state || "-"}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="bg-gray-900 rounded-lg flex flex-col gap-4 py-4">
            <h2 className="font-bold text-lg px-4">Company Info</h2>
            <table className="table style-1"><tbody>
                <tr>
                  <td className="label">Job Title</td>
                  <td width="70%">{lead.linkedInTitle || "-"}</td>
                </tr>
                <tr className="noBorder">
                  <td className="label">Name</td>
                  <td>{lead.organization?.name || "-"}</td>
                </tr>
                <tr className="noBorder">
                  <td className="label">Website</td>
                  <td>{lead.organization?.websiteUrl ? <a target="_blank" className="link" href={EntryFormatter.formatLink(lead.organization.websiteUrl)}>{lead.organization.websiteUrl}</a> : "-"}</td>
                </tr>
                <tr>
                  <td className="label">Linkedin</td>
                  <td>
                    {lead.organization?.linkedinUrl ? (
                      <a target="_blank" className="link"
                        href={EntryFormatter.formatLink(lead.organization.linkedinUrl)}
                      >{lead.organization.linkedinUrl}</a>
                    ) : "-"}
                  </td>
                </tr>
                <tr className="noBorder">
                  <td className="label">Address</td>
                  <td>{lead.organization?.address?.street || "-"}</td>
                </tr>
                <tr className="noBorder">
                  <td className="label">City</td>
                  <td>{lead.organization?.address?.city || "-"}</td>
                </tr>
                <tr className="noBorder">
                  <td className="label">State</td>
                  <td>{lead.organization?.address?.state || "-"}</td>
                </tr>
        </tbody></table></div></div>
        <div className="basis-3/5">
          <div className="bg-gray-900 rounded-lg flex flex-col gap-4 py-4 h-full">
            <h2 className="font-bold text-lg px-4">Pages Visited</h2>
            {IsPulseListLoading ? (
              <div className="flex justify-center items-center h-full">
                <Loading height="auto" spinnerSize={40} />
              </div>
            ) : pulses.length > 0 ? (
              <table className="table style-1">
                <thead>
                  <tr>
                    <th className="w-3/6" align="left">
                      <div className="flex gap-2 items-center">
                        <Icon name="globe" size={20} />
                        <div>URL</div>
                      </div>
                    </th>
                    <th>
                      <div className="flex justify-center items-center gap-2">
                        <Icon name="time" size={20} />
                        <div>Duration</div>
                      </div>
                    </th>
                    <th>
                      <div className="flex justify-center items-center gap-2">
                        <Icon name="calendar-alt" size={20} />
                        <div>Date</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pulses.map((pulse) => {
                    return (
                      <tr key={pulse.id}>
                        <td className="truncate">
                          <div
                            className={
                              "badge primary font-bold min-w-[35px] sm off truncate"
                            }
                          >
                            {pulse.pageName}
                          </div>
                        </td>
                        <td align="center">
                          <div className={"badge primary min-w-[35px] sm off"}>
                            {secondsToHMS(pulse.timeOnPage)}
                          </div>
                        </td>
                        <td align="center">
                          <div className={"badge primary min-w-[35px] sm off"}>
                            {showDate(pulse.dateVisited)}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="h-full w-full flex flex-col sm:gap-5 justify-center items-center text-white-500 text-base">
                <Icon name="page" size={60} />
                No website traffic to report from this person
              </div>
            )}
          </div>
        </div>
      </div>
  </Container>
};
export default LeadViewModule;
