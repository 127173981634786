import React from "react";
import Icon from "components/atoms/Icon/Icon";

const Heading: React.FC<{
    title: string;
    description?: string;
    icon?: string;
    iconSize?: number;
}> = ({  
    title,
    description,
    icon = "people",
    iconSize = 40,
}) => {
  return <div className="flex items-center gap-3 sm:gap-4 w-full">
    <div className="
      bg-primary-500
      flex justify-center items-center
      rounded-lg
      text-white
      w-[58px] h-[52px]
    ">
      <Icon name={icon} size={iconSize} />
    </div>
    <div className="flex flex-col flex-1 gap-0.5 md:gap-0.5 text-left">
      <h3 className="text-xl sm:text-2xl">{title}</h3>
      {description &&
        <p className="text-sm md:text-base text-gray-300">
          {description}
        </p>
      }
    </div>
</div>
};

export default Heading