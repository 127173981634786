import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ICompany, ICompanyPage, ICompanyPixel, ICompanyState } from "types/company/company.type";
import axios from "../../utils/axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

const initialState: ICompanyState = {
  company: {} as ICompany,
  isLoading: false,
  isSuccess: false,
  errorMessage: "",
  successMessage: "",
  addCredits: {
    errorMessage: "",
    isLoading: false,
    isSuccess: false,
    successMessage: "",
  },
  addCreditsToCompany: {
    errorMessage: "",
    isLoading: false,
    isSuccess: false,
    successMessage: "",
  },
  teamUserCreditsHistory: {
    data: [],
    errorMessage: "",
    isLoading: false,
    isSuccess: false,
    successMessage: "",
  },
  resetCreditsFromUser: {
    errorMessage: "",
    isLoading: false,
    isSuccess: false,
    successMessage: "",
  },
  autoRecharge: {
    errorMessage: "",
    isLoading: false,
    isSuccess: false,
    successMessage: "",
  },
  companyPixel: {
    data: {} as ICompanyPixel,
    errorMessage: "",
    isLoading: false,
    isSuccess: false,
    successMessage: "",
  },
  addCompanyPixel: {
    data: {} as ICompanyPixel,
    errorMessage: "",
    isLoading: false,
    isSuccess: false,
    successMessage: "",
  },
  companyPages: {
    data: [] as ICompanyPage[],
    errorMessage: "",
    isLoading: false,
    isSuccess: false,
    successMessage: "",
  },
  addCompanyPages: {
    data: [] as ICompanyPage[],
    errorMessage: "",
    isLoading: false,
    isSuccess: false,
    successMessage: "",
  },
  editCompanyPages: {
    data: [] as ICompanyPage[],
    errorMessage: "",
    isLoading: false,
    isSuccess: false,
    successMessage: "",
  },
  deleteCompanyPages: {
    data: [] as ICompanyPage[],
    errorMessage: "",
    isLoading: false,
    isSuccess: false,
    successMessage: "",
  },
};
export const getCompany = createAsyncThunk("company/getCompany", async (_, thunkAPI) => {
  try {
    const response = await axios.get(`${baseUrl}company`, {
      withCredentials: true,
    });

    return thunkAPI.fulfillWithValue(response.data);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
export const getTeamUserCreditsHistory = createAsyncThunk(
  "company/user/history",
  async (creditsHistoryForm: { userId: string }, thunkAPI) => {
    try {
      const response = await axios.post(`${baseUrl}company/user/history`, creditsHistoryForm, {
        withCredentials: true,
      });
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const addCreditsToUser = createAsyncThunk(
  "company/addCreditsToUser",
  async (addCreditsForm: { nOfCredits: number; userId: string }, thunkAPI) => {
    try {
      const response = await axios.post(`${baseUrl}company/addCredits`, addCreditsForm, { withCredentials: true });

      return thunkAPI.fulfillWithValue(response.data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const addCreditsToCompany = createAsyncThunk(
  "company/addCreditsToCompany",
  async (addCreditsForm: { nOfCredits: number; companyId: string }, thunkAPI) => {
    try {
      const response = await axios.post(`${baseUrl}company/addCreditsToCompany`, addCreditsForm, {
        withCredentials: true,
      });

      return thunkAPI.fulfillWithValue(response.data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const resetCompanyUserCredits = createAsyncThunk(
  "company/resetCompanyUserCredits",
  async (resetCreditsForm: { userId: string; companyId: string }, thunkAPI) => {
    try {
      const response = await axios.post(`${baseUrl}company/resetCompanyUserCredits`, resetCreditsForm, {
        withCredentials: true,
      });

      return thunkAPI.fulfillWithValue(response.data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const handleAutoRecharge = createAsyncThunk(
  "company/handleAutoRecharge",
  async (autoRechargeForm: { userId: string; amount: number; isAutoRecharge: boolean }, thunkAPI) => {
    try {
      const response = await axios.post(`${baseUrl}company/autoCharge`, autoRechargeForm, { withCredentials: true });

      return thunkAPI.fulfillWithValue(response.data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getCompanyPixel = createAsyncThunk("company/pixel", async (_, thunkAPI) => {
  try {
    const response = await axios.get(`${baseUrl}company/pixel`, {withCredentials: true});
    return thunkAPI.fulfillWithValue(response.data);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
export const createCompanyPixel = createAsyncThunk("company/pixel/new", async (_, thunkAPI) => {
  try {
    const response = await axios.post(`${baseUrl}company/pixel/new`, {
      withCredentials: true,
    });

    return thunkAPI.fulfillWithValue(response.data);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
export const getCompanyPageUrls = createAsyncThunk("client/pages/list", async (_, thunkAPI) => {
  try {
    const response = await axios.get(`${baseUrl}client/pages/list`, {
      withCredentials: true,
    });
    return thunkAPI.fulfillWithValue(response.data);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
export const addCompanyPageUrl = createAsyncThunk(
  "client/pages/add",
  async (addCompanyPageForm: { pageUrl: string; pageIntent: string }, thunkAPI) => {
    console.log("data from component", addCompanyPageForm);
    try {
      const response = await axios.post(`${baseUrl}client/pages/add`, {
        addCompanyPageForm,
        withCredentials: true,
      });
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const editCompanyPageUrl = createAsyncThunk(
  "client/pages/update",
  async (editCompanyPageForm: { id: string; pageUrl: string; pageIntent: string }, thunkAPI) => {
    try {
      const response = await axios.put(`${baseUrl}client/pages/update`, {
        editCompanyPageForm,
        withCredentials: true,
      });
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteCompanyPageUrl = createAsyncThunk("client/pages/delete", async (id: string, thunkAPI) => {
  try {
    const response = await axios.delete(`${baseUrl}client/pages/delete?id=${id}`, {
      withCredentials: true,
    });
    return thunkAPI.fulfillWithValue(response.data);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    resetAddCredits: (state) => {
      state.addCredits.errorMessage = "";
      state.addCredits.successMessage = "";
      state.addCredits.isLoading = false;
      state.addCredits.isSuccess = false;
    },
    resetAddToCompany: (state) => {
      state.addCreditsToCompany.errorMessage = "";
      state.addCreditsToCompany.successMessage = "";
      state.addCreditsToCompany.isLoading = false;
      state.addCreditsToCompany.isSuccess = false;
    },
    resetResetCompanyUserCredits: (state) => {
      state.resetCreditsFromUser.errorMessage = "";
      state.resetCreditsFromUser.successMessage = "";
      state.resetCreditsFromUser.isLoading = false;
      state.resetCreditsFromUser.isSuccess = false;
    },
    resetAutoRecharge: (state) => {
      state.autoRecharge.errorMessage = "";
      state.autoRecharge.successMessage = "";
      state.autoRecharge.isLoading = false;
      state.autoRecharge.isSuccess = false;
    },
    resetTeamUserCreditsHistory: (state) => {
      state.teamUserCreditsHistory.errorMessage = "";
      state.teamUserCreditsHistory.successMessage = "";
      state.teamUserCreditsHistory.isLoading = false;
      state.teamUserCreditsHistory.isSuccess = false;
    },
    resetCompanyPixelState: (state) => {
      state.companyPixel.data = {} as ICompanyPixel;
      state.companyPixel.errorMessage = "";
      state.companyPixel.successMessage = "";
      state.companyPixel.isLoading = false;
      state.companyPixel.isSuccess = false;
    },
    resetAddCompanyPixelState: (state) => {
      state.addCompanyPixel.data = {} as ICompanyPixel;
      state.addCompanyPixel.errorMessage = "";
      state.addCompanyPixel.successMessage = "";
      state.addCompanyPixel.isLoading = false;
      state.addCompanyPixel.isSuccess = false;
    },
    resetGetCompanyPageUrlState: (state) => {
      state.companyPages.data = [] as ICompanyPage[];
      state.companyPages.errorMessage = "";
      state.companyPages.successMessage = "";
      state.companyPages.isLoading = false;
      state.companyPages.isSuccess = false;
    },
    resetAddCompanyPageUrlState: (state) => {
      state.addCompanyPages.data = [] as ICompanyPage[];
      state.addCompanyPages.errorMessage = "";
      state.addCompanyPages.successMessage = "";
      state.addCompanyPages.isLoading = false;
      state.addCompanyPages.isSuccess = false;
    },
    resetEditCompanyPageUrlState: (state) => {
      state.editCompanyPages.data = [] as ICompanyPage[];
      state.editCompanyPages.errorMessage = "";
      state.editCompanyPages.successMessage = "";
      state.editCompanyPages.isLoading = false;
      state.editCompanyPages.isSuccess = false;
    },
    resetDeleteCompanyPageUrlState: (state) => {
      state.deleteCompanyPages.data = [] as ICompanyPage[];
      state.deleteCompanyPages.errorMessage = "";
      state.deleteCompanyPages.successMessage = "";
      state.deleteCompanyPages.isLoading = false;
      state.deleteCompanyPages.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompany.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCompany.rejected, (state, action: any) => {
      state.company = {} as ICompany;
      state.errorMessage = action.payload;
      state.isSuccess = false;
      state.successMessage = "";
      state.isLoading = false;
    });
    builder.addCase(getCompany.fulfilled, (state, action) => {
      state.company = action.payload.data;
      state.errorMessage = "";
      state.isSuccess = true;
      state.successMessage = action.payload.message;
      state.isLoading = false;
    });

    builder.addCase(getTeamUserCreditsHistory.pending, (state, action) => {
      state.teamUserCreditsHistory.isLoading = true;
    });
    builder.addCase(getTeamUserCreditsHistory.rejected, (state, action: any) => {
      state.teamUserCreditsHistory.data = [];
      state.teamUserCreditsHistory.errorMessage = action.payload.message;
      state.teamUserCreditsHistory.isLoading = false;
      state.teamUserCreditsHistory.isSuccess = false;
      state.teamUserCreditsHistory.successMessage = "";
    });
    builder.addCase(getTeamUserCreditsHistory.fulfilled, (state, action) => {
      state.teamUserCreditsHistory.data = action.payload.data.data;
      state.teamUserCreditsHistory.errorMessage = "";
      state.teamUserCreditsHistory.isSuccess = true;
      state.teamUserCreditsHistory.successMessage = action.payload.message;
      state.teamUserCreditsHistory.isLoading = false;
    });

    builder.addCase(addCreditsToUser.pending, (state, action) => {
      state.addCredits.isLoading = true;
    });
    builder.addCase(addCreditsToUser.rejected, (state, action: any) => {
      state.addCredits.errorMessage = action.payload.message;
      state.addCredits.isLoading = false;
      state.addCredits.isSuccess = false;
      state.addCredits.successMessage = "";
    });
    builder.addCase(addCreditsToUser.fulfilled, (state, action) => {
      state.addCredits.isLoading = false;
      state.addCredits.isSuccess = true;
      state.addCredits.errorMessage = "";
      state.addCredits.successMessage = action.payload.message;
    });

    builder.addCase(addCreditsToCompany.pending, (state, action) => {
      state.addCreditsToCompany.isLoading = true;
    });
    builder.addCase(addCreditsToCompany.rejected, (state, action: any) => {
      state.addCreditsToCompany.errorMessage = action.payload.message;
      state.addCreditsToCompany.isLoading = false;
      state.addCreditsToCompany.isSuccess = false;
      state.addCreditsToCompany.successMessage = "";
    });
    builder.addCase(addCreditsToCompany.fulfilled, (state, action) => {
      state.addCreditsToCompany.isLoading = false;
      state.addCreditsToCompany.isSuccess = true;
      state.addCreditsToCompany.errorMessage = "";
      state.addCreditsToCompany.successMessage = action.payload.message;
    });

    builder.addCase(resetCompanyUserCredits.pending, (state, action) => {
      state.resetCreditsFromUser.isLoading = true;
    });
    builder.addCase(resetCompanyUserCredits.rejected, (state, action: any) => {
      state.resetCreditsFromUser.errorMessage = action.payload.message;
      state.resetCreditsFromUser.isLoading = false;
      state.resetCreditsFromUser.isSuccess = false;
      state.resetCreditsFromUser.successMessage = "";
    });
    builder.addCase(resetCompanyUserCredits.fulfilled, (state, action) => {
      state.resetCreditsFromUser.isLoading = false;
      state.resetCreditsFromUser.isSuccess = true;
      state.resetCreditsFromUser.errorMessage = "";
      state.resetCreditsFromUser.successMessage = action.payload.message;
    });

    builder.addCase(handleAutoRecharge.pending, (state, action) => {
      state.autoRecharge.isLoading = true;
    });
    builder.addCase(handleAutoRecharge.rejected, (state, action: any) => {
      state.autoRecharge.errorMessage = action.payload.message;
      state.autoRecharge.isLoading = false;
      state.autoRecharge.isSuccess = false;
      state.autoRecharge.successMessage = "";
    });
    builder.addCase(handleAutoRecharge.fulfilled, (state, action) => {
      state.autoRecharge.isLoading = false;
      state.autoRecharge.isSuccess = true;
      state.autoRecharge.errorMessage = "";
      state.autoRecharge.successMessage = action.payload?.message;
    });

    builder.addCase(getCompanyPixel.pending, (state, action) => {
      state.companyPixel.isLoading = true;
    });
    builder.addCase(getCompanyPixel.rejected, (state, action: any) => {
      state.companyPixel.errorMessage = action.payload?.message || "An error occurred";
      state.companyPixel.isLoading = false;
      state.companyPixel.isSuccess = false;
      state.companyPixel.successMessage = "";
    });
    builder.addCase(getCompanyPixel.fulfilled, (state, action) => {
      state.companyPixel.isLoading = false;
      state.companyPixel.isSuccess = true;
      state.companyPixel.errorMessage = "";
      state.companyPixel.successMessage = action.payload.message;
      state.companyPixel.data = action.payload.data;
    });

    builder.addCase(createCompanyPixel.pending, (state, action) => {
      state.addCompanyPixel.isLoading = true;
    });
    builder.addCase(createCompanyPixel.rejected, (state, action: any) => {
      state.addCompanyPixel.errorMessage = action.payload.message;
      state.addCompanyPixel.isLoading = false;
      state.addCompanyPixel.isSuccess = false;
      state.addCompanyPixel.successMessage = "";
    });
    builder.addCase(createCompanyPixel.fulfilled, (state, action) => {
      state.addCompanyPixel.isLoading = false;
      state.addCompanyPixel.isSuccess = true;
      state.addCompanyPixel.errorMessage = "";
      state.addCompanyPixel.successMessage = action.payload.message;
      state.addCompanyPixel.data = action.payload.data;
      state.companyPixel.data = action.payload.data;
    });

    builder.addCase(getCompanyPageUrls.pending, (state, action) => {
      state.companyPages.isLoading = true;
    });
    builder.addCase(getCompanyPageUrls.rejected, (state, action: any) => {
      state.companyPages.errorMessage = action.payload.message;
      state.companyPages.isLoading = false;
      state.companyPages.isSuccess = false;
      state.companyPages.successMessage = "";
    });
    builder.addCase(getCompanyPageUrls.fulfilled, (state, action) => {
      state.companyPages.isLoading = false;
      state.companyPages.isSuccess = true;
      state.companyPages.errorMessage = "";
      state.companyPages.successMessage = action.payload.message;
      state.companyPages.data = action.payload.data;
    });

    builder.addCase(addCompanyPageUrl.pending, (state, action) => {
      state.addCompanyPages.isLoading = true;
    });
    builder.addCase(addCompanyPageUrl.rejected, (state, action: any) => {
      state.addCompanyPages.errorMessage = action.payload.message;
      state.addCompanyPages.isLoading = false;
      state.addCompanyPages.isSuccess = false;
      state.addCompanyPages.successMessage = "";
    });
    builder.addCase(addCompanyPageUrl.fulfilled, (state, action) => {
      state.addCompanyPages.isLoading = false;
      state.addCompanyPages.isSuccess = true;
      state.addCompanyPages.errorMessage = "";
      state.addCompanyPages.successMessage = action.payload.message;
      state.addCompanyPages.data = action.payload.data;
    });

    builder.addCase(editCompanyPageUrl.pending, (state, action) => {
      state.editCompanyPages.isLoading = true;
    });
    builder.addCase(editCompanyPageUrl.rejected, (state, action: any) => {
      state.editCompanyPages.errorMessage = action.payload.message;
      state.editCompanyPages.isLoading = false;
      state.editCompanyPages.isSuccess = false;
      state.editCompanyPages.successMessage = "";
    });
    builder.addCase(editCompanyPageUrl.fulfilled, (state, action) => {
      state.editCompanyPages.isLoading = false;
      state.editCompanyPages.isSuccess = true;
      state.editCompanyPages.errorMessage = "";
      state.editCompanyPages.successMessage = action.payload.message;
      state.editCompanyPages.data = action.payload.data;
    });

    builder.addCase(deleteCompanyPageUrl.pending, (state, action) => {
      state.deleteCompanyPages.isLoading = true;
    });
    builder.addCase(deleteCompanyPageUrl.rejected, (state, action: any) => {
      state.deleteCompanyPages.errorMessage = action.payload.message;
      state.deleteCompanyPages.isLoading = false;
      state.deleteCompanyPages.isSuccess = false;
      state.deleteCompanyPages.successMessage = "";
    });
    builder.addCase(deleteCompanyPageUrl.fulfilled, (state, action) => {
      state.deleteCompanyPages.isLoading = false;
      state.deleteCompanyPages.isSuccess = true;
      state.deleteCompanyPages.errorMessage = "";
      state.deleteCompanyPages.successMessage = action.payload.message;
      state.deleteCompanyPages.data = action.payload.data;
    });
  },
});

export const {
  resetAddCredits,
  resetAddToCompany,
  resetResetCompanyUserCredits,
  resetAutoRecharge,
  resetTeamUserCreditsHistory,
  resetCompanyPixelState,
  resetAddCompanyPixelState,
  resetGetCompanyPageUrlState,
  resetAddCompanyPageUrlState,
  resetEditCompanyPageUrlState,
  resetDeleteCompanyPageUrlState
} = companySlice.actions;
