import React, { useState } from "react";
import Creatable from "react-select/creatable";
// MORE: https://react-select.com/home
// SECOND OPTION: https://primereact.org/multiselect/
// THIRD OPTION: https://www.npmjs.com/package/react-multi-select-component
// const options = [
//   { label: "Grapes 🍇", value: "grapes" },
//   { label: "Mango 🥭", value: "mango" },
//   { label: "Strawberry 🍓", value: "strawberry", disabled: true },
// ];
const InputSelect: React.FC<{
  options: [];
  state: [];
  setState: any;
  placeholder: string;
  label?: string;
  isSearchable?: boolean;
  isMulti?: boolean;
  menuPlacement?: "auto" | "bottom" | "top";
  id?: string;
}> = ({
  options,
  state,
  setState,
  placeholder,
  label,
  isSearchable = true,
  isMulti = true,
  menuPlacement = "auto",
  id,
}) => {
  const customStyles = {
    control: (provided: any, state: { isFocused: any }) => ({
      ...provided,
      outline: "none",
      backgroundColor: "var(--color-gray-900)",
      fontSize: "14px",
      borderColor: state.isFocused ? "var(--color-primary-500)" : "var(--color-gray-700)",
      borderRadius: 8,
      boxShadow: "none",
      "&:hover": { borderColor: "var(--color-primary-500)" },
    }),
    valueContainer: (provided: any) => ({ ...provided, padding: "6px" }),
    placeholder: (provided: any) => ({ ...provided, color: "var(--color-gray-500)", }),
    input: (provided: any) => ({
      ...provided,
      color: "var(--color-white-500)",
    }),
    option: (provided: any, state: { isSelected: any; isFocused: any }) => ({
      ...provided,
      cursor: "pointer",
      backgroundColor: state.isSelected
        ? "var(--color-primary-400)"
        : state.isFocused
        ? "var(--color-primary-500)"
        : "var(--color-gray-900)",
      color: "var(--color-white-500)",
      "&:active": { backgroundColor: "var(--color-primary-500)" },
      "&:hover": { color: "white" },
    }),
    multiValue: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "var(--color-primary-500)",
      borderRadius: 4,
    }),
    multiValueLabel: (provided: any) => ({ ...provided, color: "white" }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      padding: "0 2px",
      color: "white",
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      "&:hover": {
        color: "white",
        backgroundColor: "var(--color-primary-400)",
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: 8,
      padding: "2px 0 ",
      fontSize: "14px",
      border: "1px solid var(--color-gray-700)",
      backgroundColor: "var(--color-gray-900)",
    }),
    indicatorSeparator: (provided: any) => ({ ...provided, display: "none" }),
    clearIndicator: (provided: any) => ({
      ...provided,
      color: "var(--color-gray-500)",
      cursor: "pointer",
      paddingRight: 0,
      paddingLeft: 0,
      "&:hover": { color: "var(--color-primary-500)" },
      svg: { width: 18 },
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: "var(--color-gray-500)",
      cursor: "pointer",
      "&:hover": { color: "var(--color-primary-500)" },
      svg: { width: 18 },
    }),
  };
  const menuListUiHandler = () => {
    const seniorityMenuList = document.getElementById(id || "");
    const parentElement = document.getElementById("menu-parent");
    console.log("first", parentElement?.clientHeight);
    console.log(
      "seniorityMenuList?.getBoundingClientRect()?.y",
      seniorityMenuList?.getBoundingClientRect()?.y
    );

    if (parentElement?.clientHeight) {
      if (
        parentElement.clientHeight > 350 &&
        parentElement?.clientHeight < 400
      ) {
        if (
          seniorityMenuList?.getBoundingClientRect()?.y &&
          seniorityMenuList?.getBoundingClientRect()?.y > 200 &&
          seniorityMenuList?.getBoundingClientRect()?.y < 400
        ) {
          parentElement?.scrollBy({
            top: 120,
            behavior: "smooth",
          });
        }
      } else if (
        parentElement.clientHeight > 400 &&
        seniorityMenuList?.getBoundingClientRect()?.y &&
        seniorityMenuList?.getBoundingClientRect()?.y > 400
      ) {
        parentElement?.scrollBy({ top: 80, behavior: "smooth", });
      }
    }
    // if (
    // seniorityMenuList?.getBoundingClientRect()?.y &&
    // seniorityMenuList?.getBoundingClientRect()?.y >
    //     (parentElement?.clientHeight && parentElement?.clientHeight > 500
    //       ? 400
    //       : 350)
    // ) {
    // }
  };
  return (
    <div
      id={id || ""}
      className="gap-2 flex flex-col"
      onClick={() => {
        if (id) menuListUiHandler();
      }}
    >
      {label && <p className="text-sm">{label}</p>}
      <Creatable
        menuPlacement={menuPlacement}
        options={options}
        value={state}
        onChange={setState}
        placeholder={`${placeholder}...`}
        styles={customStyles}
        isClearable
        isSearchable={isSearchable}
        isMulti={isMulti}
        formatCreateLabel={(inputValue: any) => `Search ${inputValue}`}
      />
    </div>
  );
};
export default InputSelect;
