import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axios from "../../utils/axios";
import { convertQueryString } from "utils/workWithData";
import { EnrichmentDTO } from "utils/dtos/enrichmentDTO";

interface IUpload {
  message: string;
  isLoading: boolean;
  errorMessage: string;
  isSuccess: boolean;
}
interface IListState {
  isLoading: boolean;
  lists: EnrichmentDTO[];
  errorMessage: string;
  isSuccess: boolean;
  uploadData: IUpload;
  template:any;
  isLastPage:boolean;
  numberOfLeads:number;
}

const baseUrl = process.env.REACT_APP_BASE_URL;
const clientUrl = process.env.REACT_APP_CLIENT_URL;

const initialState: IListState = {
  isLoading: false,
  lists: [],
  errorMessage: "",
  isSuccess: false,
  isLastPage: false,
  numberOfLeads: 0,
  uploadData: {
    message: "",
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
  },
  template: {
    data: null,
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
  },
};

export const getLists = createAsyncThunk(
  "lists/fetchLists",
  async (queryParams: any, thunkAPI) => {
    try {
      const query = await convertQueryString(queryParams);
      const response = await axios.get(
        `${baseUrl}lead/history/bulk?${query}`,
        {withCredentials: true}
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const downloadTemplate = createAsyncThunk(
  "lists/downloadTemplate",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`${baseUrl}lead/fetch/item_bulk_template`, {
        withCredentials: true,
      });
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const uploadData = createAsyncThunk(
  "lists/uploadList",
  async (file: any, thunkAPI) => {
    try {
      const response = await axios.post(`${baseUrl}lead/enrichment/bulk`, file, {
        withCredentials: true,
        // timeout: 60*1000,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": clientUrl,
        },
      });
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const listsSlice = createSlice({
  name: "list",
  initialState,
  reducers: {
    resetUploadData: (state) => {
      state.uploadData.errorMessage = "";
      state.uploadData.isLoading = false;
      state.uploadData.isSuccess = false;
    },
    resetListSlice: (state) => {
      state.isLoading = false;
      state.lists = [];
      state.errorMessage = "";
      state.isLastPage = false;
      state.numberOfLeads = 0;
      state.isSuccess = false;
      state.uploadData.isLoading = false;
      state.uploadData.isSuccess = false;
      state.uploadData.errorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLists.pending, (state, _action) => {
      state.isLoading = true;
    });
    builder.addCase(getLists.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.lists = [...action.payload.data.leadListDTOs, ...state.lists];
      state.isLastPage = action.payload.data.isLastPage
      state.numberOfLeads = action.payload.data.numberOfLeads
    });
    builder.addCase(getLists.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = "Something went wrong";
    });
    builder.addCase(uploadData.pending, (state, _action) => {
      state.uploadData.isLoading = true;
      state.uploadData.message = "";
    });
    builder.addCase(uploadData.fulfilled, (state, action) => {
      state.uploadData.message = action.payload.data.message;
      state.uploadData.isLoading = false;
      state.uploadData.isSuccess = true;
    });
    builder.addCase(uploadData.rejected, (state, action) => {
      state.uploadData.isLoading = false;
      state.uploadData.isSuccess = false;
      //@ts-ignore
      state.uploadData.errorMessage = action.payload?.message || 'Something went wrong';
    });
    builder.addCase(downloadTemplate.fulfilled, (state, action) => {
      state.template.data = action.payload.data;
      state.template.isLoading = false;
      state.template.errorMessage = "";
      state.template.isSuccess = true;
    });
    builder.addCase(downloadTemplate.pending, (state, action) => {
      state.template.isLoading = true;
    });
    builder.addCase(downloadTemplate.rejected, (state, action) => {
      state.template.errorMessage = action.payload;
      state.template.isLoading = false;
      state.template.data = null;
      state.template.isSuccess = false;
    });
  },
});

export const { resetUploadData, resetListSlice } = listsSlice.actions;

export const selectLists = (state: RootState) => state.lists.lists;
export const selectErrorMessage = (state: RootState) =>
  state.lists.errorMessage;
