/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import Button from "../../atoms/Button/Button";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { reset } from "store/slices/auth.slice";
import { getPersonalInfo } from "store/slices/user.slice";
import Popup from "../Popup/Popup";
import Profile from "../Profile/Profile";
import BuyCredits from "../BuyCredits/BuyCredits";
import theme from "../../../assets/themes";
import UserController from "controllers/user";
import EntryFormatter from "utils/EntryFormatter";
import Icon from "components/atoms/Icon/Icon";
import constants from "utils/constants";
import Container from "components/atoms/layout/Container/Container";
const Header: React.FC<any> = ({ loggedIn, token, showMenu = true }) => {
  const [showBuyCredits, setShowBuyCredits] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isSuccess } = useAppSelector((state) => state.auth.logout);
  const { data } = useAppSelector((state) => state.user);
  const location = useLocation();
  const handleLogout = async () => {
    await UserController.Auth.logout({ data, dispatch });
    navigate("/signin");
  };
  useEffect(() => {
    if (!isSuccess) return;
    navigate("/signin");
    dispatch(reset());
  }, [isSuccess]);
  useEffect(() => {
    if (token && !isSuccess) dispatch(getPersonalInfo());
  }, []);
  return (
    <Container>
      <header className={styles.header}>
        <div className={styles.header_container}>
          <Link to="/"><img src={theme["logo"]} alt="logo" className={styles.header_logo} /></Link>
          {showMenu && (
            <>
              {loggedIn ? (
                <>
                  <nav className={styles.header_menu}>
                    <Link
                      to={`/${constants.PIXEL_KEYWORD}`}
                      className={[`/${constants.PIXEL_KEYWORD}`].includes(location.pathname) ? `active` : ""}
                    >
                      <div className="flex items-center gap-2 capitalize">
                        <Icon name="pulse" />
                        {constants.PIXEL_KEYWORD}
                      </div>
                    </Link>
                    <Link
                      to={`/${constants.LEADSEARCH_KEYWORD}`}
                      className={[`/${constants.LEADSEARCH_KEYWORD}`].includes(location.pathname) ? `active` : ""}
                    >
                      <div className="flex items-center gap-2">
                        <Icon name="search" />
                        {constants.LEADSEARCH_KEYWORD}
                      </div>
                    </Link>
                  </nav>
                  <nav className={styles.header_nav}>
                    {!EntryFormatter.isObjectEmpty(data) && (
                      <>
                        {!(data.hasUnlimitedCredits || data.isInvitedUser) && (
                          <>
                            <Button iconName="credits" onClick={() => setShowBuyCredits(true)}>Buy Credits</Button>
                            {showBuyCredits && (
                              <Popup
                                onClose={() => setShowBuyCredits(false)}
                                type="modal" noButton
                                bodyClass={styles.header_nav_popup}
                              >
                                <BuyCredits />
                              </Popup>
                            )}
                          </>
                        )}
                        <Profile
                          name={{
                            name: data.firstName,
                            lastName: data.lastName,
                          }}
                          credits={data.creditBalance}
                          unlimitedCredits={data.hasUnlimitedCredits}
                          logout={handleLogout}
                          dropDown={true}
                        />
                      </>
                    )}
                  </nav>
                  <div className={styles.header_nav_burger}><BurgerMenu /></div>
                </>
              ) : (
                <nav className={styles.header_nav}>
                  <Button type="primary" onClick={() => navigate("/signin")}>Log In</Button>
                  <Button type="primary" onClick={() => navigate("/signup")}>Sign Up</Button>
                </nav>
              )}
            </>
          )}
        </div>
      </header>
    </Container>
  );
};
export default Header;
