import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { ICard, ICardState } from "types/card/card.types";

const baseUrl = process.env.REACT_APP_BASE_URL;

const initialState: ICardState = {
  isLoading: false,
  data: [],
  defaultCard: {} as ICard,
  isSuccess: false,
  errorMessage: "",
  setDefaultCard: {
    isLoading: false,
    successData: null,
    isSuccess: false,
    errorData: null,
  },
  payment: {
    isLoading: false,
    successMessage: { piClientSecret: "" },
    isSuccess: false,
    errorMessage: "",
    data: {
      nOfTransactions: 0,
      totalSpentInUSD: 0,
      piClientSecret: ""
    }
  },
  deleteCard: {
    isLoading: false,
    successMessage: "",
    isSuccess: false,
    errorMessage: "",
  },
  addCard: {
    isLoading: false,
    successData: null,
    isSuccess: false,
    errorData: null,
  },
};
export const getCards = createAsyncThunk(
  "payment/getCards",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`${baseUrl}payment/payment_cards`, {
        withCredentials: true,
      });
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const setDeafultCard = createAsyncThunk(
  "card/setDefault",
  async (paymentId: string, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseUrl}payment/default_payment_card`,
        {stripePaymentMethodId: paymentId,},
        {withCredentials: true}
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const paySelectedCard = createAsyncThunk(
  "card/payWithSelected",
  async (data: any, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseUrl}payment`,
        { token: data.card, amount: data.amount, cardDetails: data.cardType },
        {withCredentials: true}
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteCard = createAsyncThunk(
  "card/deleteCard",
  async (data: any, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseUrl}payment/deleteCard`,
        { cardId: data },
        {withCredentials: true}
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const addCard = createAsyncThunk("card/addCard", async (data: any, thunkAPI) => {
  try {
    const response = await axios.post(
      `${baseUrl}payment/payment_card`,
      data,
      {withCredentials: true}
    );
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});
export const handlePay = createAsyncThunk("card/handlePay", async (data: any, thunkAPI) => {
  try {
    const responsePayment = await axios.post(`${baseUrl}payment`,
      { amount: data.amount,
        stripePaymentMethodId: data.stripePaymentMethodId,
        numberOfCredits: +data.numberOfCredits,
        isSubscription: data.isSubscription,
        couponId: data?.couponId,
        appScheme: data?.appScheme,
      },
      {withCredentials: true}
    );
    const responsePurchases = await axios.get(
      `${baseUrl}payment/purchases_total`,
      { withCredentials: true }
    );
    return thunkAPI.fulfillWithValue({
      ...responsePayment.data,
      userPurchases: (responsePurchases && responsePurchases?.data) || null
    });
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const cardSlice = createSlice({
  name: "card",
  initialState,
  reducers: {
    resetPayment: (state) => {
      state.payment.errorMessage = "";
      state.payment.successMessage = { piClientSecret: "" };
      state.payment.isLoading = false;
      state.payment.isSuccess = false;
    },
    resetDeleteCard: (state) => {
      state.deleteCard.errorMessage = "";
      state.deleteCard.isLoading = false;
      state.deleteCard.isSuccess = false;
      state.deleteCard.errorMessage = "";
    },
    resetAddCard: (state) => {
      state.addCard.errorData = null;
      state.addCard.isLoading = false;
      state.addCard.isSuccess = false;
      state.addCard.successData = null;
    },
    resetSetDefault: (state) => {
      state.addCard.errorData = null;
      state.addCard.isLoading = false;
      state.addCard.isSuccess = false;
      state.addCard.successData = null;
    },
    resetSetDefaultCard: (state) => {
      state.setDefaultCard.errorData = null;
      state.setDefaultCard.isLoading = false;
      state.setDefaultCard.isSuccess = false;
      state.setDefaultCard.successData = null;
    },
    resetCardSlice: (state) => {
      state.isLoading = false;
      state.data = [];
      state.defaultCard = {} as ICard;
      state.isSuccess = false;
      state.errorMessage = "";
      state.payment.isLoading = false;
      state.payment.successMessage = { piClientSecret: "" };
      state.payment.isSuccess = false;
      state.payment.errorMessage = "";
      state.deleteCard.isLoading = false;
      state.deleteCard.successMessage = "";
      state.deleteCard.isSuccess = false;
      state.deleteCard.errorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCards.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCards.fulfilled, (state, action) => {
      state.isLoading = false;
      state.errorMessage = "";
      state.data = [
        ...action.payload.data.otherPaymentCards,
      ];
      if (action.payload.data.defaultPaymentCard) {
        state.data = [
          ...state.data,
          action.payload.data.defaultPaymentCard
        ]
      }
      state.defaultCard = action.payload.data.defaultPaymentCard;
      state.isSuccess = true;
    });
    builder.addCase(getCards.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
      state.data = [];
      state.isSuccess = false;
    });
    builder.addCase(paySelectedCard.pending, (state, action) => {
      state.payment.isLoading = true;
    });
    builder.addCase(paySelectedCard.fulfilled, (state, action) => {
      state.payment.isLoading = false;
      state.payment.errorMessage = "";
      state.payment.successMessage = action.payload;
      state.payment.isSuccess = true;
    });
    builder.addCase(paySelectedCard.rejected, (state, action) => {
      state.payment.isLoading = false;
      state.payment.errorMessage = action.payload;
      state.payment.successMessage = { piClientSecret: "" };
      state.payment.isSuccess = false;
    });
    builder.addCase(handlePay.pending, (state, action) => {
      state.payment.isLoading = true;
    });
    builder.addCase(handlePay.fulfilled, (state, action) => {
      state.payment.isLoading = false;
      state.payment.errorMessage = "";
      state.payment.data = action.payload.data;
      if (action.payload!.userPurchases && action.payload.userPurchases!.data) {
        state.payment.data = {
          ...state.payment.data,
          ...action.payload.userPurchases.data
        }
      }
      state.payment.successMessage = action.payload.message;
      state.payment.isSuccess = true;
    });
    builder.addCase(handlePay.rejected, (state, action: any) => {
      state.payment.isLoading = false;
      state.payment.errorMessage = action.payload?.message || 'Something went wrong';
      state.payment.successMessage = { piClientSecret: "" };
      state.payment.isSuccess = false;
    });
    builder.addCase(deleteCard.pending, (state, action) => {
      state.deleteCard.isLoading = true;
    });
    builder.addCase(deleteCard.fulfilled, (state, action) => {
      state.deleteCard.isLoading = false;
      state.deleteCard.errorMessage = "";
      state.deleteCard.successMessage = action.payload;
      state.deleteCard.isSuccess = true;
    });
    builder.addCase(deleteCard.rejected, (state, action) => {
      state.deleteCard.isLoading = false;
      state.deleteCard.errorMessage = action.payload;
      state.deleteCard.successMessage = "";
      state.deleteCard.isSuccess = false;
    });
    builder.addCase(addCard.pending, (state, action) => {
      state.addCard.isLoading = true;
    });
    builder.addCase(addCard.fulfilled, (state, action) => {
      state.addCard.isLoading = false;
      state.addCard.errorData = null;
      state.addCard.successData = action.payload;
      state.addCard.isSuccess = true;
    });
    builder.addCase(addCard.rejected, (state, action) => {
      state.addCard.isLoading = false;
      state.addCard.errorData = action.payload;
      state.addCard.successData = null;
      state.addCard.isSuccess = false;
    });
    builder.addCase(setDeafultCard.pending, (state, action) => {
      state.setDefaultCard.isLoading = true;
    });
    builder.addCase(setDeafultCard.fulfilled, (state, action) => {
      state.setDefaultCard.isLoading = false;
      state.setDefaultCard.errorData = null;
      state.setDefaultCard.successData = action.payload.data;
      state.setDefaultCard.isSuccess = true;
    });
    builder.addCase(setDeafultCard.rejected, (state, action: any) => {
      state.setDefaultCard.isLoading = false;
      state.setDefaultCard.errorData = action.payload?.data;
      state.setDefaultCard.successData = null;
      state.setDefaultCard.isSuccess = false;
    });
  },
});

export const {
  resetPayment,
  resetDeleteCard,
  resetCardSlice,
  resetAddCard,
  resetSetDefault,
  resetSetDefaultCard,
} = cardSlice.actions;
