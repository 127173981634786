import React from "react";
import { Navigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useAppDispatch } from "store/hooks";
import { logout, resetAuthSlice } from "store/slices/auth.slice";
import { resetCardSlice } from "store/slices/card.slice";
import { resetListSlice } from "store/slices/list.slice";
import { resetTransactionsSlice, resetUpdateUser } from "store/slices/transactions.slice";

const ProtectedBack = ({ children }: any) => {
  const dispatch = useAppDispatch()

  
  const token = localStorage.getItem("accessToken");
  let decodedToken: any;
  try {
    decodedToken = jwt_decode(token || "");
    
    if (Date.now() > decodedToken.exp * 1000) {
      decodedToken = false;
      localStorage.removeItem("accessToken");
      dispatch(logout());
      dispatch(resetAuthSlice());
      dispatch(resetCardSlice());
      dispatch(resetListSlice());
      dispatch(resetTransactionsSlice());
      dispatch(resetUpdateUser());
    }
  } catch (e) {
    localStorage.removeItem("accessToken");
    decodedToken = false
  }
  return (
    <React.Fragment>{decodedToken ? <Navigate to={"/dashboard"} /> : children}</React.Fragment>
  );
};

export default ProtectedBack;