import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useLocation } from "react-router-dom";
import SegmentAgent from "apis/segmentAgent";
import { resetSignUp } from "store/slices/auth.slice";
import PulseModule from "components/modules/Pulse/PulseModule";
import SearchMainModule from "components/modules/SearchMain/SearchMainModule";

// const templateLink ="https://middlewarexdevxstorage.blob.core.windows.net/dev/template.csv";
const Dashboard: React.FC<any> = () => {
  const { isSuccess: authIsSuccess, user: authUser } = useAppSelector((state) => state.auth.signUp);
  const { data: user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const location = useLocation()

  useEffect(() => {
    if (!authIsSuccess) return
    if (authUser && authUser.isFirstLogin && !authUser.hasUnlimitedCredits) { dispatch(resetSignUp()) }
  }, [authIsSuccess]) // eslint-disable-line
  useEffect(() => {
    window.scrollTo(0, 0);
    SegmentAgent.page('home', {pathName: location.pathname} )
  }, []) // eslint-disable-line  
  return (
      <>
      {/* {open && (
        <Popup
          onClose={()=>setOpen(false)}
          text="You have 10 free Credits!"
          buttonText="Close"
          type="message"
          bodyClass={styles.container_popup}
        />
      )} */}
      {user.platformBrandId === 2 
        ? <SearchMainModule />
        : <PulseModule />
      }
    </>
  );
};
export default Dashboard;
