import { Icon, Button, Loading, Tooltip } from "components";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../../../../utils/axios";
import { EnrichmentDTO } from "utils/dtos/enrichmentDTO";
import LeadHelper from "helpers/LeadHelper";
const baseUrl = process.env.REACT_APP_BASE_URL;
const BulkItem: React.FC<{ data: EnrichmentDTO; index: number }> = ({
  data,
  index,
}) => {
  const [singleData, setSingleData] = useState<EnrichmentDTO>(data);
  const [isLoading, setIsLoading] = useState(false);
  const downloadFile = (content: any, fileName: any) => {
    const blob = new Blob([content], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const handleFileExport = async () => {
      setIsLoading(true);
      let blobName = singleData.downloadUrl,
        listId = singleData.enrichmentId;
      try {
        let response;
        if (listId) {
          response = await axios.get(
            `${baseUrl}lead/fetch/item_sheet_download?id=${listId}`
          );
        } else
          response = await axios.get(`${baseUrl}lead/fetch/item_bulk_template`);
        if (!blobName)
          return toast.error("No file found. Please try again later.");
        downloadFile(
          response.data!.data,
          blobName!.substring(blobName!.lastIndexOf("/") + 1)
        );
      } catch (err: any) {
        console.log(err);
        toast.error("Something went wrong. Please try again later.");
      }
      setIsLoading(false);
    },
    showName = () => {
      if (singleData.name) return singleData.name;
      return "-";
    },
    showDate = () => {
      const date = Date.parse(String(singleData.enrichmentStartedDate)),
        isToday =
          new Date(date).setHours(0, 0, 0, 0) ===
          new Date().setHours(0, 0, 0, 0),
        dateFormatted =
          (!isToday ? new Date(date).toLocaleDateString("en-US") + " " : ``) +
          new Date(date).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          });
      return String(dateFormatted);
    };
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        !LeadHelper.isReadyForDownload(singleData.status) &&
        singleData.enrichmentId
      ) {
        axios
          .get(`${baseUrl}enrichment/fetch/item?id=${singleData.enrichmentId}`)
          .then((res: any) => {
            if (res.status !== 200) return;
            if (LeadHelper.isReadyForDownload(res.data.data.status)) {
              setSingleData(res.data.data);
              clearInterval(interval);
            }
          })
          .catch((err: any) => {
            console.log(err);
          });
      } else clearInterval(interval);
    }, 30000); // every 30 seconds it verifies if the data is ready
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <tr
      style={{
        borderTop:
          index === 0
            ? "1px solid var(--color-paper-600)"
            : "1px solid var(--color-paper-600)",
      }}
    >
      <td>{showName()}</td>
      <td className="hidden sm:table-cell">
        {LeadHelper.isReadyForDownload(singleData.status) && (
          <div className="flex items-center justify-center gap-2">
            <Tooltip content="Number of Leads">
              <div className="badge sm primary">
                <Icon name="people" size={17} />
                {singleData.numberOfLeads}
              </div>
            </Tooltip>
            <Tooltip content="Enriched">
              <div
                className={`badge sm ${
                  singleData.numberOfLeadsEnriched > 0 ? "primary" : "off"
                }`}
              >
                <Icon name="search-check" size={17} />
                {singleData.numberOfLeadsEnriched}
              </div>
            </Tooltip>
            <Tooltip content="Not Enriched">
              <div className="badge sm off">
                <Icon name="search-x" size={17} />
                {singleData.numberOfLeadsNotEnriched}
              </div>
            </Tooltip>
          </div>
        )}
      </td>
      <td className="hidden sm:table-cell text-center" title={showDate()}>
        {showDate()}
      </td>
      <td>
        <div className="flex justify-center">
          {!isLoading ? (
            <>
              {LeadHelper.isReadyForDownload(singleData.status) ? (
                <>
                  {singleData.downloadUrl && (
                    <Button
                      size="sm"
                      iconName="download"
                      style={{ width: "max-content" }}
                      onClick={handleFileExport}
                    >
                      Export
                    </Button>
                  )}
                </>
              ) : (
                <div className="badge off h-[38px]">
                  <Loading
                    height="auto"
                    spinnerSize={15}
                    color="var(--color-white-500)"
                  />
                  <span>Processing...</span>
                </div>
              )}
            </>
          ) : (
            <Loading height="auto" spinnerSize={38} />
          )}
        </div>
      </td>
    </tr>
  );
};
export default BulkItem;
