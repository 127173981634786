import Button from "components/atoms/Button/Button";
import React, { useEffect } from "react";
import styles from "./style.module.scss";
import classNames from "classnames";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { getPersonalInfo } from "store/slices/user.slice";
import SegmentAgent from "apis/segmentAgent";
import { Icon } from "components";

const SuccessPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation()
  useEffect(() => {
    SegmentAgent.page('paymentSuccess', {pathName: location.pathname} )
    dispatch(getPersonalInfo())
  },[]) // eslint-disable-line
  return (
    <div className={classNames(styles.container)}>
      {Array.from({ length: 3 }, (_, i) => i).map((elm) => {
        return (
          <div
            className={styles.circle}
            style={{
              width: `${144 + (elm + 1) * 64}px`,
              height: `${144 + (elm + 1) * 64}px`,
              opacity: elm === 1 ? 1 : 0.5,
            }}
            key={elm}
          >
            {elm === 1 && (
              <div className={styles.circle_filled}>
                <Icon name="credits" />
              </div>
            )}
          </div>
        )
      })}
      <div className={styles.box}>
        <h2 className="text-3xl">Payment Complete</h2>
        <div className={styles.box_text}>
          <h1 className="text-2xl text-white-500 mt-9 mb-2">+{(searchParams.get("amount") !== 'undefined' && searchParams.get("amount"))|| ""} Credits</h1>
          <p>The credits will be added to your account within minutes.</p>
        </div>
      </div>
      <Button onClick={() => navigate("/")}>Go to Dashboard</Button>
    </div>
  );
};

export default SuccessPage;
