/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Container from "components/atoms/layout/Container/Container";
import SearchMainBody from "./SearchMainBody";
import SearchMainFilters from "./SearchMainFilters";
import "./index.scss";
import Icon from "components/atoms/Icon/Icon";
import constants from "utils/constants";
import { useSearchParams } from "react-router-dom";
import { parseJSON } from "utils/parseJson";
import { useAppDispatch } from "store/hooks";
import { getSearchMainCount, getSearchMainData } from "store/slices/searchMain.slice";
const CONTENT_CLASS = `bg-gray-900 rounded-lg flex flex-col h-[76vh]`;
const SearchMainModule: React.FC<any> = () => {
  // ⭐ Filters
  const [filterJobTitle, setFilterJobTitle] = useState<any[]>([]);
  const [filterSeniority, setFilterSeniority] = useState<any[]>([]);
  const [filterDepartment, setFilterDepartment] = useState<any[]>([]);
  const [filterIndustry, setFilterIndustry] = useState<any[]>([]);
  const [filterCompany, setFilterCompany] = useState<any[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isAppliedFilter, setIsAppliedFilter] = useState(false);
  const [pageCurrent, setPageCurrent] = useState<number>(1);
  const [orderColumn, setOrderColumn] = useState<"person_name" | "company_name" | "job_title" | "date" | undefined>();
  const [orderMethod, setOrderMethod] = useState<"asc" | "desc">("desc");
  const [filterCompanyHeadcount, setFilterCompanyHeadcount] = useState<any[]>([]);
  const [filterCompanyRevenue, setFilterCompanyRevenue] = useState<any[]>([]);
  const [filterLocationCompany, setFilterLocationCompany] = useState<any[]>([]);
  const [filterLocationPerson, setFilterLocationPerson] = useState<any[]>([]);
  const [filterGender, setFilterGender] = useState<any[]>([]);
  const [filterIncomeRange, setFilterIncomeRange] = useState<any[]>([]);
  // ⭐ Search Inputs
  const [searchPeople, setSearchPeople] = useState<string | number>("");
  const [searchLinkedin, setSearchLinkedin] = useState<any>("");
  const [searchParams] = useSearchParams();
  useEffect(() => {
    // @ts-ignore
    const params = Object.fromEntries([...searchParams]);
    if (params.name) setSearchPeople(params.name);
    if (params.linkedin) setSearchLinkedin(params.linkedin);
    if (params.person_location) setFilterLocationPerson(parseJSON(params.person_location));
    if (params.gender) setFilterGender(parseJSON(params.gender));
    if (params.job_title) setFilterJobTitle(parseJSON(params.job_title));
    if (params.seniority) setFilterSeniority(parseJSON(params.seniority));
    if (params.department) setFilterDepartment(parseJSON(params.department));
    if (params.industry) setFilterIndustry(parseJSON(params.industry));
    if (params.company_name) setFilterCompany(parseJSON(params.company_name));
    if (params.company_location) setFilterLocationCompany(parseJSON(params.company_location));
    if (params.company_headcount) setFilterCompanyHeadcount(parseJSON(params.company_headcount));
    if (params.company_revenue) setFilterCompanyRevenue(parseJSON(params.company_revenue));
    if (params.income_range) setFilterIncomeRange(parseJSON(params.income_range));
  }, []);
  const dispatch = useAppDispatch();
  const pageSize = 25;
  const personLocationFromUrl = searchParams.get("person_location")
    ? JSON.parse(searchParams.get("person_location") || "") : [];
  const genderFromUrl = searchParams.get("gender") ? JSON.parse(searchParams.get("gender") || "") : [];
  const jobTitleFromUrl = searchParams.get("job_title") ? JSON.parse(searchParams.get("job_title") || "") : [];
  const seniorityFromUrl = searchParams.get("seniority") ? JSON.parse(searchParams.get("seniority") || "") : [];
  const departmentFromUrl = searchParams.get("department") ? JSON.parse(searchParams.get("department") || "") : [];
  const industryFromUrl = searchParams.get("industry") ? JSON.parse(searchParams.get("industry") || "") : [];
  const companyNameFromUrl = searchParams.get("company_name")
    ? JSON.parse(searchParams.get("company_name") || "") : [];
  const companyLocationFromUrl = searchParams.get("company_location")
    ? JSON.parse(searchParams.get("company_location") || "") : [];
  const companyHeadcountFromUrl = searchParams.get("company_headcount")
    ? JSON.parse(searchParams.get("company_headcount") || "") : [];
  const getQuery = () => {
    return {
      page: searchParams.get("current_page") || pageCurrent,
      size: pageSize,
      search_name: searchPeople || searchParams.get("name") || "",
      search_linkedin: searchLinkedin || searchParams.get("linkedin") || "",
      job_titles:
        filterJobTitle.length > 0
          ? JSON.stringify(filterJobTitle.map((i) => i.value)) : jobTitleFromUrl.length > 0
          ? JSON.stringify(jobTitleFromUrl.map((i: any) => i.value)) : ``,
      companies:
        filterCompany.length > 0
          ? JSON.stringify(filterCompany.map((i) => i.value)) : companyNameFromUrl.length > 0
          ? JSON.stringify(companyNameFromUrl.map((i: any) => i.value)) : ``,
      company_headcounts:
        filterCompanyHeadcount.length > 0
          ? JSON.stringify(filterCompanyHeadcount.map((i) => i.value)) : companyHeadcountFromUrl.length > 0
          ? JSON.stringify(companyHeadcountFromUrl.map((i: any) => i.value)) : ``,
      company_revenues:
        filterCompanyRevenue.length > 0 ? JSON.stringify(filterCompanyRevenue.map((i) => i.value)) : ``,
      industries:
        filterIndustry.length > 0
          ? JSON.stringify(filterIndustry.map((i) => i.value)) : industryFromUrl?.length > 0
          ? JSON.stringify(industryFromUrl.map((i: any) => i.value)) : ``,
      genders:
        filterGender.length > 0
          ? JSON.stringify(filterGender.map((i) => i.value)) : genderFromUrl?.length > 0
          ? JSON.stringify(genderFromUrl.map((i: any) => i.value)) : ``,
      seniorities:
        filterSeniority.length > 0
          ? JSON.stringify(filterSeniority.map((i) => i.value)) : seniorityFromUrl.length > 0
          ? JSON.stringify(seniorityFromUrl.map((i: any) => i.value)) : ``,
      departments:
        filterDepartment.length > 0
          ? JSON.stringify(filterDepartment.map((i) => i.value)) : departmentFromUrl.length > 0
          ? JSON.stringify(departmentFromUrl.map((i: any) => i.value)) : ``,
      locations_person:
        filterLocationPerson.length > 0
          ? JSON.stringify(filterLocationPerson.map((i) => i.value)) : personLocationFromUrl.length > 0
          ? JSON.stringify(personLocationFromUrl.map((i: any) => i.value)) : ``,
      locations_company:
        filterLocationCompany.length > 0
          ? JSON.stringify(filterLocationCompany.map((i) => i.value)) : companyLocationFromUrl.length > 0
          ? JSON.stringify(companyLocationFromUrl.map((i: any) => i.value)) : ``,
      income_ranges: filterIncomeRange.length > 0 ? JSON.stringify(filterIncomeRange.map((i) => i.value)) : ``,
      order_column: orderColumn,
      order_method: orderMethod,
    };
  };
  const fetchResults = async () => {
    setIsSearching(true);
    dispatch(getSearchMainCount(getQuery()));
    await dispatch(getSearchMainData(getQuery()));
    setIsSearching(false);
  };
  useEffect(() => {
    fetchResults();
  }, [searchParams.get("current_page")]);
  return (
    <Container>
      <div className="flex sm:gap-6">
        <div className="flex basis-1/4">
          <div className="items-center flex gap-3 text-white-500">
            <Icon name="search" size={30} />
            <h1 className="font-bold capitalize text-2xl">
              {constants.LEADSEARCH_KEYWORD}
            </h1>
          </div>
        </div>
      </div>
      <div className="flex sm:gap-6">
        <div className={`${CONTENT_CLASS} basis-1/5 gap-3 sm:flex hidden`}>
          <SearchMainFilters pageCurrent={pageCurrent} setPageCurrent={setPageCurrent}
            isAppliedFilter={isAppliedFilter} setIsAppliedFilter={setIsAppliedFilter} isSearching={isSearching}
            fetchResults={fetchResults} searchPeople={searchPeople} setSearchPeople={setSearchPeople}
            searchLinkedin={searchLinkedin} setSearchLinkedin={setSearchLinkedin}
            filterIncomeRange={filterIncomeRange} setFilterIncomeRange={setFilterIncomeRange}
            filterGender={filterGender} setFilterGender={setFilterGender} filterJobTitle={filterJobTitle}
            setFilterJobTitle={setFilterJobTitle} filterCompany={filterCompany} setFilterCompany={setFilterCompany}
            filterIndustry={filterIndustry} setFilterIndustry={setFilterIndustry}
            filterLocationPerson={filterLocationPerson} setFilterLocationPerson={setFilterLocationPerson}
            filterLocationCompany={filterLocationCompany} setFilterLocationCompany={setFilterLocationCompany}
            filterSeniority={filterSeniority} setFilterSeniority={setFilterSeniority}
            filterDepartment={filterDepartment} setFilterDepartment={setFilterDepartment}
            filterCompanyHeadcount={filterCompanyHeadcount} setFilterCompanyHeadcount={setFilterCompanyHeadcount}
            filterCompanyRevenue={filterCompanyRevenue} setFilterCompanyRevenue={setFilterCompanyRevenue} />
        </div>
        <div className={`SearchMainBody ${CONTENT_CLASS} w-full sm:w-auto sm:basis-4/5`}>
          <SearchMainBody isAppliedFilter={isAppliedFilter} setIsAppliedFilter={setIsAppliedFilter}
            fetchResults={fetchResults} orderMethod={orderMethod} setOrderMethod={setOrderMethod}
            pageCurrent={pageCurrent} setPageCurrent={setPageCurrent} orderColumn={orderColumn}
            setOrderColumn={setOrderColumn} isSearching={isSearching} setIsSearching={setIsSearching}
            searchPeople={searchPeople} setSearchPeople={setSearchPeople} searchLinkedin={searchLinkedin}
            filterJobTitle={filterJobTitle} filterCompany={filterCompany}
            filterCompanyHeadcount={filterCompanyHeadcount} filterCompanyRevenue={filterCompanyRevenue}
            filterIndustry={filterIndustry} filterLocationPerson={filterLocationPerson}
            filterLocationCompany={filterLocationCompany} filterGender={filterGender}
            filterSeniority={filterSeniority} filterDepartment={filterDepartment}
            filterIncomeRange={filterIncomeRange} />
        </div>
      </div>
      {/* {isModalOpen && (
        <Popup onClose={() => setIsModalOpen(false)} type="modal">
          <ExportModal
            selectedRows={selectedRows}
            setIsModalOpen={setIsModalOpen}
            setSelectedRows={setSelectedRows}
          />
        </Popup>
      )} */}
    </Container>
  );
};
export default SearchMainModule;
