import React, { FC, useEffect, useState } from "react";
import Icon from "components/atoms/Icon/Icon";
import InputSelect from "components/atoms/InputSelect/InputSelect";
import { OPTIONS_COMPANY, OPTIONS_COMPANY_HEADCOUNT, OPTIONS_COMPANY_REVENUE, OPTIONS_DEPARTMENT, OPTIONS_GENDER, OPTIONS_INCOME_RANGE, OPTIONS_INDUSTRY, OPTIONS_JOBTITLE, OPTIONS_LOCATION, OPTIONS_SENIORITY } from "../SearchMain/SearchMainHelper";
import Button from "components/atoms/Button/Button";
import InputField from "components/atoms/InputField/InputField";
const PulseFilters: React.FC<{
  searchPeople: string | number,
  setSearchPeople: React.Dispatch<React.SetStateAction<string | number>>,
  searchLinkedin: any,
  setSearchLinkedin: React.Dispatch<React.SetStateAction<any>>,
  filterJobTitle: any[]
  setFilterJobTitle: React.Dispatch<React.SetStateAction<any[]>>,
  filterCompany: any[],
  setFilterCompany: React.Dispatch<React.SetStateAction<any[]>>,
  filterIndustry: any[],
  setFilterIndustry: React.Dispatch<React.SetStateAction<any[]>>,
  filterLocationPerson: any[],
  setFilterLocationPerson: React.Dispatch<React.SetStateAction<any[]>>,
  filterGender: any[],
  setFilterGender: React.Dispatch<React.SetStateAction<any[]>>,
  filterSeniority: any[],
  setFilterSeniority: React.Dispatch<React.SetStateAction<any[]>>,
  filterDepartment: any[],
  setFilterDepartment: React.Dispatch<React.SetStateAction<any[]>>,
  filterLocationCompany: any[],
  setFilterLocationCompany: React.Dispatch<React.SetStateAction<any[]>>,
  filterCompanyHeadcount: any[],
  setFilterCompanyHeadcount: React.Dispatch<React.SetStateAction<any[]>>,
  filterIncomeRange: any[],
  setFilterIncomeRange: React.Dispatch<React.SetStateAction<any[]>>,
  filterCompanyRevenue: any[],
  setFilterCompanyRevenue: React.Dispatch<React.SetStateAction<any[]>>,
}> = ({
  searchPeople, setSearchPeople,
  searchLinkedin, setSearchLinkedin,
  filterGender, setFilterGender,
  filterJobTitle, setFilterJobTitle,
  filterCompany, setFilterCompany,
  filterIndustry, setFilterIndustry,
  filterLocationPerson, setFilterLocationPerson,
  filterLocationCompany, setFilterLocationCompany,
  filterSeniority, setFilterSeniority,
  filterDepartment, setFilterDepartment,
  filterCompanyHeadcount, setFilterCompanyHeadcount,
  filterIncomeRange, setFilterIncomeRange,
  filterCompanyRevenue, setFilterCompanyRevenue
}) => {
  return <>
    <h1 className="font-bold text-xl px-5 pt-5 pb-2">Filters</h1>
    <ul className="SearchMainFilter">
      <FilterItem label="Person Location" icon="user-location"
        state={filterLocationPerson}
        setState={setFilterLocationPerson}
        isActive={filterLocationPerson.length > 0}
        options={OPTIONS_LOCATION}
        placeholder="Enter locations"
      />
      <FilterItem 
        label="Linkedin" icon="linkedin" type="input"
        state={searchLinkedin} setState={setSearchLinkedin}
        isActive={searchLinkedin.toString().length > 0}
        placeholder="Enter a LinkedIn URL"
      />
      <FilterItem 
        label="Job Title" icon="job"
        state={filterJobTitle} setState={setFilterJobTitle}
        isActive={filterJobTitle.length > 0}
        options={OPTIONS_JOBTITLE} 
        placeholder="Enter job titles"
      />
      <FilterItem 
        label="Company Name" icon="company"
        state={filterCompany} setState={setFilterCompany}
        isActive={filterCompany.length > 0}
        options={OPTIONS_COMPANY}
        placeholder="Enter companies"
      />
  </ul></>
}
export default PulseFilters
const FilterItem: FC<any> = ({ type = "select", state, setState, label, labelInput, icon, isActive, options, placeholder, isSearchable = true, isMulti = true, menuPlacement = "auto" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState<string | number>("");
  useEffect(() => {
    if (state === searchInput || type !== "input") return;
    setSearchInput(state as string);
  }, [state]); // eslint-disable-line
  return (
    <li
      className={`${isActive ? "is_active" : ""} ${isOpen ? "is_open" : ""}`}
      onClick={() => { if (!isOpen) setIsOpen(!isOpen); }}
    >
      <div className="content-title flex items-center justify-evenly gap-2" onClick={() => setIsOpen(!isOpen)}>
        <div className="w-full flex items-center gap-2"><Icon name={icon} size={20} /> {label}</div>
        {isActive && <Button size="xxs" onClick={(e: any) => { e.stopPropagation(); setState([]); }}>
          {type === "input" ? 1 : state?.length}
          <Icon name="close" size={12} />
        </Button>}
        <Icon name={isOpen ? "caret-up" : "caret-down"} size={15} />
      </div>
      {isOpen && (
        <div className="px-[20px] pt-0 pb-4">
          {type === "input" && (
            <form className="flex gap-2 items-center"
              onSubmit={(e) => {
                e.preventDefault();
                setState(searchInput);
              }}
            >
              <InputField variant="dark" placeholder={placeholder} size="small" value={searchInput} onChange={(e: any) => setSearchInput(e.target.value)} />
              <Button iconName="search" iconSize={16} size="xs" onClick={() => setSearchInput(searchInput)}>Search</Button>
            </form>
          )}
          {type === "select" && (
            <InputSelect id={label === "Seniority" ? "seniority-select" : ""} label={labelInput} options={options}
              state={state} setState={setState} placeholder={placeholder} isSearchable={isSearchable}
              isMulti={isMulti} menuPlacement={menuPlacement} />
          )}
        </div>
      )}
    </li>
  );
};