import Icon from 'components/atoms/Icon/Icon';
import Loading from 'components/atoms/Loading/Loading';
import Table from 'components/atoms/Table/Table';
import Heading from 'components/atoms/layout/Heading/Heading';
import React from 'react'
import { ICompanyTransaction } from 'types/company/company.type';
import { showDate } from 'utils/workWithData';

const CompanyTeamHistory: React.FC<{
    history: ICompanyTransaction[];
    errorMessageHistory: string | null;
    isLoadingHistory: boolean;
    isSuccessHistory: boolean;
}> = ({
    history,
    errorMessageHistory,
    isLoadingHistory,
    isSuccessHistory,
}) => {
    return (<>
        <div className="flex flex-col w-full gap-4">
            <Heading
                icon='document' iconSize={33}
                title="Credits History"
                description="See the credits history of your team."
            />
            {isLoadingHistory && <Loading height="200px" />}
            {isSuccessHistory && (
                history && history.length > 0 ? (
                    <Table>
                        <thead><tr>
                            <th className="w50">Date</th>
                            <th className="w50">Amount</th>
                        </tr></thead>
                        <tbody>
                            {history.map((item) => {
                                return <tr key={item.id}>
                                    <td title={showDate(item.createdDate)}>{showDate(item.createdDate)}</td>
                                    <td className="text-center">
                                        <div className="flex justify-center items-center gap-2">
                                            <Icon name="credits" />
                                            {item.amount}
                                        </div>
                                    </td>
                                </tr>
                            })}
                    </tbody></Table>
                ) : (
                    <div className="flex flex-col items-center w-full py-10 gap-5">
                        <Icon name="credits" size={60} />
                        <p className="text-base">No Credits History found</p>
                    </div>
                )
            )}
            {errorMessageHistory && <p>{errorMessageHistory}</p>}
        </div>
    </>)
}
export default CompanyTeamHistory
