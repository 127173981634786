import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import Icon from "../Icon/Icon";
import Loading from "../Loading/Loading";
import { Link } from "react-router-dom";

const Button: React.FC<{
  onClick?: any;
  size?: "xxs" | "xs" | "sm" | "md" | "lg" | "xl";
  type?: "outline" | "secondary" | "primary" | "white" | "download" | "inactive";
  className?: string;
  style?: React.CSSProperties;
  children?: string | React.ReactNode;
  disabled?: boolean;

  isProcessing?: boolean;
  isProcessingTitle?: string;

  isLink?: boolean;
  href?: string;

  action?: "button" | "submit" | "reset";
  
  iconName?: string;
  iconSize?: number;
  id?: string;
}> = ({
  onClick,
  type,
  size = 'md',
  className,
  style,
  children,
  disabled,

  isProcessing,
  isProcessingTitle = "Processing...",

  isLink,
  href,

  action,
  iconName,
  iconSize = 20,
  id
}) => {
  const buttonClass = classNames(
    styles.button,
    type && styles[`button_${type}`],
    styles[`button_${size}`],
    isProcessing && styles[`button_processing`],
    className
  );
  if (isLink) return <Link
    className={buttonClass}
    target="_blank" rel="noreferrer"
    to={href || "#"}
  >
    {children && <span style={{ width: "max-content" }}>
      {!isProcessing ? children : isProcessingTitle}
    </span>}
  </Link>
  return (
    <button
      onClick={(e)=>{
        if (disabled || isProcessing) return;
        if (onClick) onClick(e);
      }}
      className={buttonClass}
      style={style}
      disabled={disabled}
      type={action}
      id={id}
    >
      <div className="flex items-center gap-1">
        {iconName && <>
          {!isProcessing
            ? <Icon name={iconName} size={iconSize} />
            : <Loading height="23px" width="auto" color="white" spinnerSize={iconSize} />
          }
        </>}
        {children && <div className="flex items-center gap-1 w-max">
          {
            !isProcessing
              ? children
              : isProcessingTitle
          }
        </div>}
      </div>
    </button>
  );
};

export default Button;
