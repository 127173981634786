/* eslint-disable react-hooks/exhaustive-deps */
import { Route, Routes, useLocation } from "react-router-dom";
import Signup from "../pages/User/Auth/Signup";
import Signin from "../pages/User/Auth/Signin";
import ResetPasswordPage from "../pages/ResetPassword/ResetPassword";
import MyAccount from "../pages/MyAccount/MyAccount";
import Home from "../pages/Dashboard/Dashboard";
import AccessDenied from "../pages/AccessDenied/AccessDenied";
import React, { useEffect, useState } from "react";
import Protected from "layouts/ProtectedLayout/ProtectedLayout";
import ProtectedBack from "layouts/ProtectedLayout/ProtecteBack";
import Main from "pages/Main/Main";
import Error from "pages/Error/Error";
import EmailVerify from "pages/EmailVerify/EmailVerify";
import Billing from "pages/Billing/Billing";
import SuccessPage from "pages/SuccessPage/SuccessPage";
import BuyCredits from "components/molecules/BuyCredits/BuyCredits";
import SegmentAgent from "apis/segmentAgent";
import { useAppSelector } from "store/hooks";
import EntryFormatter from "utils/EntryFormatter";
import TermsOfService from "pages/Institucional/TermsOfService";
import CompanyPage from "pages/Company/Company";
import PulseModule from "components/modules/Pulse/PulseModule";
import SearchMainModule from "components/modules/SearchMain/SearchMainModule";
import LeadViewModule from "components/modules/Lead/View/LeadViewModule";
import constants from "utils/constants";
import LeadHistoryBulk from "components/modules/Lead/History/HistoryBulk";
import { IntercomContext } from "utils/context/IntercomContext";

declare global {
  interface Window {
    analytics?: any;
  }
}
const localStorageSessionStartTimeKey = "sessionStartTime";
const MyRouter: React.FunctionComponent = () => {
  const isMobile = window.innerWidth <= 810; // Adjust the breakpoint as needed
  const location = useLocation();
  const [locationCurrent, setLocationCurrent] = useState("");
  const { data: user } = useAppSelector((state) => state.user);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const handleSessionIsOver = () => {
    const durationInSeconds = (Date.now() - Number(localStorage.getItem(localStorageSessionStartTimeKey))) / 1000;
    SegmentAgent.event(
      { userId: user.segmentUserId,
        durationInMilliSeconds: Math.floor(durationInSeconds) * 1000,
        durationInSeconds: Math.floor(durationInSeconds),
        durationInMinutes: Math.floor(durationInSeconds / 60),
      },
      user.segmentUserId, "USER_SESSION_DURATION", user.platformBrandId
    );
    localStorage.removeItem(localStorageSessionStartTimeKey);
  };
  useEffect(() => {
    if (EntryFormatter.isObjectEmpty(user) || (EntryFormatter.hasValue(locationCurrent) && locationCurrent === location.pathname && isPageLoaded)) return;
    setIsPageLoaded(true);
    setLocationCurrent(location.pathname);
  }, [user, location]);
  useEffect(() => {
    if (
      EntryFormatter.isObjectEmpty(user) ||
      location.pathname === "/user/billing/success"
    )
      return;
    const storedStartTime = localStorage.getItem(
      localStorageSessionStartTimeKey
    );
    if (!storedStartTime) {
      SegmentAgent.event(
        {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          dateLogged: new Date(),
          company: EntryFormatter.exists(user.organization)
            ? user.organization?.name
            : user.organizationName,
          companyId: EntryFormatter.exists(user.organization)
            ? user.organization?.id
            : null,
          creditBalance: user.creditBalance,
          creditPurchase: user.creditPurchase || 0,
          creditUsage: user.creditUsage || 0,
          revenue: user.revenue || 0,
        },
        user.segmentUserId,
        "",
        user.platformBrandId,
        "identify"
      );
      SegmentAgent.event(
        { dateStarted: new Date() },
        user.segmentUserId,
        "USER_SESSION_START",
        user.platformBrandId
      );
      localStorage.setItem(
        localStorageSessionStartTimeKey,
        Date.now().toString()
      );
    }
    window.addEventListener("beforeunload", handleSessionIsOver);
    return () => {window.removeEventListener("beforeunload", handleSessionIsOver);};
  }, [user]);

  return (
    <Routes>
      <Route path="/" element={
        <Protected>
          <IntercomContext>
            <Home />
          </IntercomContext>
        </Protected>
      } />
      <Route path="/dashboard" element={
        <Protected>
          <IntercomContext>
            <Home />
          </IntercomContext>
        </Protected>
      } />
      <Route
        path={`/${constants.PIXEL_KEYWORD}`}
        element={
        <Protected>
          <IntercomContext>
            <PulseModule />
          </IntercomContext>
        </Protected>
      }
      />
      <Route
        path={`/${constants.LEADSEARCH_KEYWORD}`}
        element={
        <Protected>
          <IntercomContext>
            <SearchMainModule />
          </IntercomContext>
        </Protected>
      }
      />
      <Route
        path="/lead/:leadId"
        element={<Protected><LeadViewModule /></Protected>}
      />
      <Route
        path="/user/myAccount"
        element={
          <Protected>
            <MyAccount />
          </Protected>
        }
      />
      <Route
        path="/user/billing"
        element={<Protected><Billing /></Protected>}
      />
      <Route
        path="/user/billing/success"
        element={
          <Protected>
            <SuccessPage />
          </Protected>
        }
      />
      <Route
        path="/company"
        element={
          <Protected>
            <CompanyPage />
          </Protected>
        }
      />
      <Route path="/accessDenied" element={<AccessDenied />} />
      <Route
        path="/resetPassword"
        element={
          <ProtectedBack>
            <ResetPasswordPage />
          </ProtectedBack>
        }
      />
      <Route
        path="/signup"
        element={
          <ProtectedBack>
            <Signup />
          </ProtectedBack>
        }
      />
      <Route
        path="/signin"
        element={
          <ProtectedBack>
            <Signin />
          </ProtectedBack>
        }
      />
      <Route
        path="/welcome"
        element={
          <ProtectedBack>
            <Main />
          </ProtectedBack>
        }
      />
      {/* Institucional */}
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/auth/verifyAccount/:token" element={<EmailVerify />} />
      <Route path="*" element={<Error />} />
      {/* Mobile Routes */}
      <Route
        path="/buyCredits"
        element={
          isMobile 
            ? <Protected><BuyCredits /></Protected>
            : <Error />
        }
      />
      <Route path="/exports" element={<Protected><LeadHistoryBulk /></Protected>} />
    </Routes>
  );
};
export default MyRouter;
