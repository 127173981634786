import { useEffect, useState } from "react";
import styles from "./style.module.scss";
import Button from "components/atoms/Button/Button";
import { cancelSubscription, getSubscriptions, resetSubscriptionSlice } from "store/slices/subscription.slice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { toast } from "react-toastify";
import { showDate } from "utils/workWithData";
import Popup from "../Popup/Popup";
import BuyCredits from "../BuyCredits/BuyCredits";
import Heading from "components/atoms/layout/Heading/Heading";
import EntryFormatter from "utils/EntryFormatter";

const SubscriptionInfo = () => {
  const dispatch = useAppDispatch();
  const [showBuyCredits, setShowBuyCredits] = useState<boolean>(false);
  const [showCancelSubscription, setShowCancelSubscription] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { isSuccess } = useAppSelector((state) => state.subscription);
  const subscriptionData = useAppSelector((state) => state.subscription.data);
  const { isSuccess: isCancelSuccess, isLoading: isCancelLoading, errorMessage: cancelErrorMsg } = useAppSelector((state) => state.subscription.cancelSubscription);

  useEffect(() => {
    dispatch(getSubscriptions());
  }, []);
  useEffect(() => {
    if (cancelErrorMsg) toast.error(cancelErrorMsg);
    if (!isCancelSuccess) return
    toast.success("Your subscription has been canceled successfully.");
    dispatch(resetSubscriptionSlice());
    dispatch(getSubscriptions());
  }, [isCancelSuccess, cancelErrorMsg]);
  const handleCancelSubscription = () => {dispatch(cancelSubscription(subscriptionData!));};
  // const handleChangeSubscription = () => {
  //   setIsEditing(true);
  //   setShowBuyCredits(true);
  // };
  if (!subscriptionData) return null
  return <div className={`flex flex-col w-full gap-5`}>
    <Heading title="Membership & Credits" icon="membership" iconSize={30} description="Update or cancel your subscription plan." />
    <div className={styles.main}>
      {isSuccess && subscriptionData 
        ? <div className="gap-3 flex flex-col">
            <p className="text-2xl font-bold">Current Plan</p>
            <p className="text-gray-300">{`You are subscribed to ${subscriptionData?.credits} credits every month.`}</p>
            <p className="mt-3 text-2xl font-bold">Current Billing Cycle</p>
            <p className="text-gray-300">
              {`${showDate(subscriptionData.currentPeriodStart, false, true)} - ${showDate(subscriptionData.currentPeriodEnd, false, true)} — ${EntryFormatter.displayPrice(subscriptionData.priceAmount)} paid`}
            </p>
            <div className="flex gap-4 mt-4">
              {/* NOTE: commented it for now */}
              {/* <Button size="md" onClick={handleChangeSubscription}>Edit Membership Plan</Button> */}
              <Button 
                // className="ml-2"
                type="download" size="md" onClick={setShowCancelSubscription}>Unsubscribe</Button>
            </div>
        </div>
        : <p>No Subscription found</p>
      }
      {showBuyCredits && <Popup onClose={() => setShowBuyCredits(false)}>
        <BuyCredits isEditing={isEditing} subscriptionData={isEditing ? subscriptionData : null} />
      </Popup>}
      {showCancelSubscription &&
        <Popup onClose={() => setShowCancelSubscription(false)}>
          <div className="flex flex-col items-start gap-5 w-full">
            <p className="text-xl font-bold">Are you sure you want to cancel your subscription?</p>
            <div className="flex gap-4">
              <Button size="md" onClick={handleCancelSubscription} isProcessing={isCancelLoading}>Yes, cancel my subscription</Button>
              <Button size="md" onClick={() => setShowCancelSubscription(false)}>No, keep my subscription</Button>
            </div>
          </div>
        </Popup>
      }
    </div>
  </div>
};
export default SubscriptionInfo;