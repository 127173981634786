import React, { useEffect, useMemo } from "react";
import { RowData, flexRender, Row } from "@tanstack/react-table";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { InterfacePixelLeadData, getLeadStats } from "store/slices/insights";
import { secondsToHMS } from "utils/workWithData";
import LeadScore from "components/atoms/LeadScore/LeadScore";
import Skeleton from "components/atoms/layout/Skeleton/Skeleton";
import { useNavigate } from "react-router-dom";
import constants from "utils/constants";
declare module "@tanstack/react-table" {
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: "text" | "range" | "select" | "none";
    size?: string;
}}
const PulseRow: React.FC<{ row: Row<InterfacePixelLeadData> }> = ({ row }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { leadsStats } = useAppSelector((state) => state.insights);
  const getRowStats = useMemo(() => async () => {
    if (stats) return;
    dispatch(getLeadStats({ leadId: row.original.id }));
  }, [row.original.id]);
  const stats = leadsStats.find((i) => i.leadId === row.original.id);
  useEffect(() => { getRowStats(); }, []);
  return <tr>
      {row.getVisibleCells().map((cell) => {
        const value = cell.getValue() as any;
        return <td key={cell.id} className={`py-4 px-3`}
          onClick={() => {navigate(`/lead/${row.original.id}?app_source=${constants.PIXEL_KEYWORD}`)}}
          style={{
            textAlign: cell.column.id === "orgName" ? "left" : "center",
            width: cell.column.columnDef.meta?.size ?? "auto",
          }}
          title={ typeof value === "string" && value.length > 12 ? value : ""}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
          {cell.column.id === "leadScore" &&
            <>{stats?.leadScore !== undefined 
              ? <div className="flex justify-center"><LeadScore value={stats.leadScore} /></div>
              : <Skeleton width={40} height={20} />}</>
          }
          {cell.column.id === "visits" &&
            <>{stats?.visits !== undefined ? stats.visits : <Skeleton width={40} height={20} />}</>
          }
          {cell.column.id === "pageViews" && 
            <>{stats?.pageViews !== undefined ? stats.pageViews : <Skeleton width={40} height={20} />}</>
          }
          {cell.column.id === "duration" && 
            <>{stats?.duration !== undefined ? secondsToHMS(stats.duration) : <Skeleton width={40} height={20} />}</>
          }
        </td>
      })}
  </tr>
};
export default PulseRow;