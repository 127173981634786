import SegmentAgent from "apis/segmentAgent";
import { logout, resetAuthSlice } from "store/slices/auth.slice";
import { resetCardSlice } from "store/slices/card.slice";
import { resetDataSlice } from "store/slices/data.slice";
import { resetInsights } from "store/slices/insights";
import { resetListSlice } from "store/slices/list.slice";

import {
  resetTransactionsSlice,
  resetUpdateUser,
} from "store/slices/transactions.slice";

import { resetUser } from "store/slices/user.slice";

export default class UserController {
  static Auth = class {
    static async logout({ user, dispatch }: any) {
      if (user && user.segmentUserId) {
        SegmentAgent.event(
          {
            logoutAt: new Date(),
          },
          user.segmentUserId,
          "USER_LOGOUT",
          user.platformBrandId
        );
      }

      await dispatch(logout());
      await dispatch(resetAuthSlice());
      await dispatch(resetCardSlice());
      await dispatch(resetDataSlice());
      await dispatch(resetListSlice());
      await dispatch(resetTransactionsSlice());
      await dispatch(resetUpdateUser());
      await dispatch(resetUser());
      await dispatch(resetInsights());

      return true;
    }
  };
}
