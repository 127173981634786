import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  addCard,
  getCards,
  handlePay,
  resetAddCard,
  resetPayment,
  resetSetDefaultCard,
  setDeafultCard,
} from "store/slices/card.slice";
import Loading from "components/atoms/Loading/Loading";
import classNames from "classnames";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import CardItem from "../CardItem/CardItem";
import { getCreditPrice, prepareForSaveCard } from "utils/workWithData";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import SegmentAgent from "apis/segmentAgent";
import { Button, Icon, Heading } from "components";
import constants from "utils/constants";
import theme from "../../../assets/themes";
import { createSubscription, resetSubscriptionSlice, updateSubscription } from "store/slices/subscription.slice";
import { PaymentPlanType } from "utils/enums/paymentENUM";
import { useAppContext } from "utils/context/AppContext";
const baseUrl = process.env.REACT_APP_CLIENT_URL;

const PaymentMethod: React.FC<any> = ({
  className,
  classBody,
  noHeader,
  heightLimited,
  enableCheckbox,
  showPay,
  isInModal,
  numberOfCredits,
  amount,
  isSubscription,
  frequency,
  isEditing,
  subscriptionData,
}) => {
  const { app } = useAppContext();
  const location = useLocation();
  const { data: cards, isLoading, isSuccess: cardSuccess, defaultCard } = useAppSelector((state) => state.cards);
  const { data: coupon } = useAppSelector((state) => state.coupon);
  const { data: user } = useAppSelector((state) => state.user);
  const {
    isLoading: loadingAddCard,
    errorData: errorAddCard,
    isSuccess: addCardSuccess,
  } = useAppSelector((state) => state.cards.addCard);
  const {
    isLoading: setDefaultLoading,
    isSuccess: setDefaultSuccess,
    errorData: setDefaultError,
  } = useAppSelector((state) => state.cards.setDefaultCard);
  const {
    isLoading: paymentLoading,
    isSuccess: paymentSuccess,
    errorMessage: paymentError,
    data: dataHandlePay,
  } = useAppSelector((state) => state.cards.payment);

  const {isSuccess: isSubCreateSuccess, isLoading: isSubCreateProcessing, errorMessage: SubCreateErrorMsg } = useAppSelector((state) => state.subscription.createSubscription);
  const {isSuccess: isSubUpdateSuccess, isLoading: isSubUpdateProcessing} = useAppSelector((state) => state.subscription.updateSubscription);
  const dispatch = useAppDispatch();
  const stripe: any = useStripe();
  const elements: any = useElements();
  const [cardValidation, setCardValidation] = useState<any>();
  const [dateValidation, setDateValidation] = useState<any>();
  const [cvcValidation, setCvcValidation] = useState<any>();
  const [saveNewMethod, setSaveNewMethod] = useState(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<string>("");
  const isComplete = !(cvcValidation?.complete && dateValidation?.complete && cardValidation?.complete);
  const priceTotal = getCreditPrice(user?.platformBrandId, user.userId) * amount;
  const inputStyle = { color: theme["--text-color-W"], fontSize: "16px", "::placeholder": { color: "rgb(107 114 128)" }, }
  const handleSetDefault = (stripeMethodId: string) => {
    if (stripeMethodId) return dispatch(setDeafultCard(stripeMethodId));
    toast.warning("Something goes wrong...");
  };
  const handleAddCard = async () => {
    const { paymentMethod, error } = await stripe.createPaymentMethod({ type: "card", card: elements.getElement(CardCvcElement, CardExpiryElement, CardNumberElement) });
    if (paymentMethod) {
      if (cards.length === 0 && showPay) setIsProcessingPayment(true);
      const cardPrepared = prepareForSaveCard(paymentMethod);
      await dispatch(addCard(cardPrepared));
      return cardPrepared.id;
    } else {
      toast.warning(error && error?.code === "card_declined" ? `Oh snap! Your credit card was declined. Please try another one` : "Please check your card details and try again", { toastId: "payment_card_error" })
      return null;
    }
  };
  const pay = async () => {
    if (!numberOfCredits) return toast.warn("Amount is missing");
    if (numberOfCredits < constants.CREDITS_PURCHASE_LIMIT_MINIMUM) return toast.warn(`The minimum amount of credits you can purchase is ${constants.CREDITS_PURCHASE_LIMIT_MINIMUM}.`);
    let cardPaymentMethodId = selectedCard;
    if (cards.length === 0) cardPaymentMethodId = await handleAddCard();
    if (!cardPaymentMethodId) return toast.warn("Please select a payment card", { toastId: "payment_card_error" });
    if (!isProcessingPayment) setIsProcessingPayment(true);
    if (!isSubscription) {
      dispatch( handlePay({ amount, numberOfCredits, stripePaymentMethodId: cardPaymentMethodId, couponId: coupon?.id, appScheme: app.scheme }) );
      return
    }
    dispatch(
      !isEditing
        ? createSubscription({
          amount: frequency === PaymentPlanType.YEARLY ? amount * 12 : amount,
          numberOfCredits,
          stripePaymentMethodId: cardPaymentMethodId,
          frequency,
          couponId: coupon?.id
        })
        : updateSubscription({
          amount: frequency === PaymentPlanType.YEARLY ? amount * 12 : amount,
          numberOfCredits,
          stripePaymentMethodId: cardPaymentMethodId,
          frequency,
          subscriptionData,
          couponId: coupon?.id
        })
    )
  };
  useEffect(() => {
    if (isInModal && location.pathname === "/user/billing") return;
    if (addCardSuccess) {
      if (user && user.segmentUserId) {
        SegmentAgent.event({}, user.segmentUserId, "PAYMENT_METHOD_ADDED", user.platformBrandId);
      }
      if ((showPay && cards.length > 0) || !showPay) {
        toast.success("Your card was successfully added!");
        dispatch(getCards());
        setSaveNewMethod(false);
        dispatch(resetAddCard());
      }
    } else if (errorAddCard) {
      toast.error(String(typeof errorAddCard === "object" ? errorAddCard.message : errorAddCard || "Something went wrong"))
      dispatch(resetAddCard());
    }
  }, [loadingAddCard]);
  useEffect(() => {
    if (cardSuccess && defaultCard) { setSelectedCard(defaultCard.stripePaymentMethodId); }
  }, [isLoading]);
  useEffect(() => {
    if (setDefaultSuccess) {
      toast.success("Your default card changed", {toastId: "default-card-success"});
      dispatch(resetSetDefaultCard());
      dispatch(getCards());
    } else if (setDefaultError?.errorMessage) {
      toast.error(setDefaultError?.errorMessage);
      dispatch(resetSetDefaultCard());
      setIsProcessingPayment(false);
    }
  }, [setDefaultSuccess, setDefaultLoading]);

  const confirmPayment = async (secret: string) => {
    if (amount === undefined) return;
    if (user && user.segmentUserId) {
      const transactionId = dataHandlePay.piClientSecret.split("_secret_");
      SegmentAgent.event(
        { transactionCurrency: "USD",
          transactionAmount: isSubscription && frequency === PaymentPlanType.YEARLY ? priceTotal * 12 : priceTotal,
          transactionId: Array.isArray(transactionId) ? transactionId[0] : null,
          transactionCreditPurchased: Number(amount),
          userCreditBalance: Number(user.creditBalance) + Number(amount),
          userStatus:
            dataHandlePay.totalSpentInUSD > 0 && dataHandlePay.nOfTransactions > 0 ? "USER_PAID" : "USER_FREE",
          userRevenue: (dataHandlePay.totalSpentInUSD + priceTotal) * 100,
          userTransactionVolume: dataHandlePay.nOfTransactions + 1,
        },
        user.segmentUserId,
        "CREDITS_PURCHASED",
        user.platformBrandId
      );
    }
    const { error } = await stripe.confirmPayment({
      clientSecret: secret,
      confirmParams: {
        return_url: `${baseUrl}/user/billing/success?amount=${numberOfCredits}`,
      },
    });
    if (error) {
      toast.error("Payment was declined", { toastId: "payment-error" });
      setIsProcessingPayment(false);
    }
  };

  useEffect(() => {
    if (SubCreateErrorMsg) {
      toast.error(String(SubCreateErrorMsg), { toastId: "subscription-error" });
      setIsProcessingPayment(false);
      dispatch(resetSubscriptionSlice());
      return
    }
    if (isSubCreateSuccess || isSubUpdateSuccess) {
      setIsProcessingPayment(false);
      toast.success(isSubCreateSuccess ? "You are now subscribed! 🚀" : "Your subscription plan has been updated", { toastId: "subscription-success" });
      dispatch(resetSubscriptionSlice());
      setTimeout(() => {
        window.open(`/user/billing`, "_self");
      }, 800);
    }
    if (paymentSuccess && dataHandlePay && !isSubscription) {
      confirmPayment(dataHandlePay?.piClientSecret);
    } else if (paymentError) {
      toast.error(String(paymentError), { toastId: "payment-error" });
      dispatch(resetPayment());
      setIsProcessingPayment(false);
    }
  }, [paymentLoading, isSubCreateSuccess, isSubUpdateSuccess, SubCreateErrorMsg]);
  if (isLoading) return <Loading height="max-height" />;
  return <div className={classNames(styles.main, className)}>
      {!noHeader && <Heading icon="card" title="Billing Methods" description="Add, update, or remove your billing methods." />}
      <div className={`${styles.main_container} ${classBody}`}>
        {saveNewMethod || !cards.length ? (
          <div className={styles.main_container_card}>
            {loadingAddCard || paymentLoading || isProcessingPayment 
            ? <Loading height="100px" />
            : (<>
                <div className={styles.main_container_card_inputBox}>
                  <label>Card Number</label>
                  <fieldset className={styles.main_container_card_row_input}>
                    <CardNumberElement
                      options={{
                        showIcon: true, style: { base: inputStyle },
                      }}
                      onChange={(e: any) => setCardValidation(e)}
                    />
                  </fieldset>
                </div>
                <div className={styles.main_container_card_row}>
                  <div className={styles.main_container_card_inputBox}>
                    <label>Expiration</label>
                    <fieldset>
                      <CardExpiryElement
                        options={{ style: { base: inputStyle } }}
                        onChange={(e: any) => setDateValidation(e)}
                      />
                    </fieldset>
                  </div>
                  <div className={styles.main_container_card_inputBox}>
                    <label>CVC</label>
                    <fieldset>
                      <CardCvcElement options={{ style: { base: inputStyle } }} onChange={(e: any) => setCvcValidation(e)} />
                    </fieldset>
                  </div>
                </div>
                {/* TODO Explain why we need save card details checkbox */}
                {/* <label className={styles.main_container_card_save}>
                  <input
                    onChange={() => setSave(!save)}
                    type="checkbox"
                    name="save"
                    id="save"
                  />
                  <span>Save card details</span>
                </label> */}
              </>
            )}
          </div>
        ) : (
          <div className={ heightLimited ? `${styles.main_container_allCards} ${styles.main_container_allCards_heightLimited}` : styles.main_container_allCards}>
            {setDefaultLoading 
            ? <Loading height="max-content" />
            : cards?.map(
                (card: any): JSX.Element => (
                  <CardItem
                    key={card.stripePaymentMethodId}
                    card={card}
                    selectedCard={selectedCard}
                    enableCheckbox={enableCheckbox}
                    changeSelectedCard={setSelectedCard}
                    handleSetDefault={handleSetDefault}
                  />
                )
            )}
          </div>
        )}
        {saveNewMethod || !cards.length ? (
          <>
            {((showPay && cards.length > 0) || saveNewMethod || (!showPay && !cards.length)) && (
              <div
                className={styles.main_container_card_actions}
                style={{ display: "flex", justifyContent: saveNewMethod || !cards.length || isInModal ? "space-between" : "flex-end", }}
              >
                {(saveNewMethod || !cards.length || isInModal) && (
                  <div
                    className="text-white-500 bg-gray-600 rounded-full w-8 h-8 flex items-center justify-center cursor-pointer hover:opacity-70"
                    onClick={() => setSaveNewMethod(false)}
                  >
                    <span className="pr-0.5"><Icon name="chevron-left" size={17} /></span>
                  </div>
                )}
                <Button disabled={isComplete || loadingAddCard} onClick={() => handleAddCard()}>Save payment method</Button>
              </div>
            )}
          </>
        ) : (
          <div className={styles.main_container_allCards_actions}>
            <Button type="download" iconName="plus" onClick={() => setSaveNewMethod(true)}>Add new payment method</Button>
          </div>
        )}
        {showPay && (
          <Button
            onClick={pay}
            style={{ width: "100%", fontWeight: "bold" }} size="lg"
            isProcessing={paymentLoading || isProcessingPayment || isSubCreateProcessing || isSubUpdateProcessing}
          >
            {isSubscription ? `Confirm Subscription` : `Confirm Payment`}
          </Button>
        )}
      </div>
  </div>
};

export default PaymentMethod;
