import React, { useEffect, useMemo, } from 'react'
import {
    ExpandedState,
    useReactTable,
    getCoreRowModel,
    getPaginationRowModel,
    getExpandedRowModel,
    ColumnDef,

    Column,
    ColumnFiltersState,
    RowData,
    flexRender,
    getFilteredRowModel,
    getSortedRowModel,

} from "@tanstack/react-table";
import "../../../SearchMain/index.scss";
import InputField from 'components/atoms/InputField/InputField';
import Tooltip from 'components/atoms/Tooltip/Tooltip';
import Icon from 'components/atoms/Icon/Icon';
import Table from 'components/atoms/Table/Table';
import InputDropDown from 'components/atoms/Dropdown/InputDropdown';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { IInsightsCompanySub, getAllCompanies } from 'store/slices/insights';
import Loading from 'components/atoms/Loading/Loading';
import { showDate } from 'utils/workWithData';
import EntryFormatter from 'utils/EntryFormatter';
import constants from 'utils/constants';
import { RootState } from 'store/store';
import Heading from 'components/atoms/layout/Heading/Heading';
import { CopyBlock, a11yDark as themeBlock } from "react-code-blocks";
import { toast } from 'react-toastify';
import PixelHelper from 'helpers/PixelHelper';
import { getCompanyPixel } from 'store/slices/company.slice';
// example: https://codesandbox.io/p/devbox/misty-bash-nrosmi?file=%2Fsrc%2Fmain.tsx
// https://codesandbox.io/search?refinementList%5Btemplate%5D=&refinementList%5Bnpm_dependencies.dependency%5D%5B0%5D=%40tanstack%2Freact-table&page=1&configure%5BhitsPerPage%5D=12
const isMobile = window.innerWidth <= 810
const InsightsModule: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const { companies, isSuccess: insightsIsSuccess, isCompaniesLoading, } = useAppSelector((state) => state.insights);
  const [isLoading, setIsLoading] = React.useState(true)
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
  const [expanded, setExpanded] = React.useState<ExpandedState>({});
  const data = useMemo(() => companies, [companies])
  const { data: user, isSuccess: userIsSuccess } = useAppSelector((state) => state.user)
  const clientTrackingPixel = useAppSelector((state: RootState) => state.company.companyPixel);
  const columns = React.useMemo<ColumnDef<IInsightsCompanySub>[]>(() => 
  isMobile ? [
    {   accessorKey: "name",
        header: () => "Company",
        cell: ({ row, getValue, }) => {
          const value: any = getValue();
          let dataSecondary: string | null = null;
          if (row.original.city) {
            dataSecondary = row.original.city;
            if (row.original.state) dataSecondary += `, ${row.original.state}`;
          }
          return <>
            {value && <div className="font-bold truncate">{value}</div>}
            {dataSecondary && <div className="text-sm text-gray-500 mt-1 truncate">{dataSecondary}</div>}
          </>
        },
    },
  ]
  : [
    {   accessorKey: "name",
        header: () => "Company",
        cell: ({ row, getValue, }) => {
          const value: any = getValue();
          let dataSecondary: string | null = null;
          if (row.original.city) {
            dataSecondary = row.original.city;
            if (row.original.state) dataSecondary += `, ${row.original.state}`;
          }
          return <>
            {value && <div className="font-bold truncate text-white-500">{value}</div>}
            {dataSecondary && <div className="text-sm text-gray-500 mt-1 truncate">{dataSecondary}</div>}
          </>
        },
    },
    {   accessorKey: "visitLast",
        header: () => "Last Seen",
        cell: ({ row, getValue }) => {
          const value = getValue() as string;
          return <div className='relative flex items-center justify-center' style={{minWidth: 120 }}>
            {value !== undefined && <div className={`text-sm text-white-500`}>{showDate(value)}</div>}
            <button
              {...{
                onClick: row.getToggleExpandedHandler(),
                style: {
                  cursor: "pointer",
                  position: 'absolute',
                  borderTop: "5px solid rgb(156, 163, 175)",
                  borderLeft: "5px solid transparent",
                  borderRight: "5px solid transparent",
                  right: 0,
                  transform: row.getIsExpanded() ? 'rotate(-180deg)' : "rotate(0deg)",
                  transition: 'transform 0.5s ease'
              }}}
            ></button>
          </div>
        },
        meta: { filterVariant: 'none', size: '25%' },
    },
  ]
  , [])
  const table = useReactTable({
    data, columns,
    filterFns: {},
    state: { columnFilters, expanded },
    initialState: {
      sorting: [{ id: "visitLast", desc: true }],
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    paginateExpandedRows: false,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
  })
  useEffect(() => {
    dispatch(getAllCompanies({ }))
    dispatch(getCompanyPixel());
  }, [])
  useEffect(() => {
    if (!insightsIsSuccess || !userIsSuccess) return
    setIsLoading(false)
  }, [isCompaniesLoading, insightsIsSuccess, userIsSuccess]) 
  if (isLoading) return <Loading height="220px" />
  // ⭐ Verifications to ensure the user has access to the Insights module
  if (!EntryFormatter.hasValue(user.clientId)) return <div className="text-white-500 text-center flex flex-col gap-3 justify-center items-center p-4 py-12 sm:py-24">
    <Icon name="sad" size={70} />
    <h1 className="text-xl mt-5">You have no Insights</h1>
    <p className="text-gray-400 text-base">Your current subscription status restricts access to Insights.</p>
    <p className="text-gray-400 mb-5 text-base">Please contact our team by the email <a className="link" href={`mailto:${constants.COMPANY_1_EMAIL_SUPPORT}`}>{constants.COMPANY_1_EMAIL_SUPPORT}</a>. We are here to help you enjoy the full range of services we offer.</p>
  </div>
  if (!user.hasVerifiedEmail) return <div className="flex flex-col gap-3 justify-center items-center p-4 py-12 sm:py-24 text-white-500 text-center">
    <Icon name="email" size={70} />
    <h1 className="text-xl mt-5">Please verify your email</h1>
    <p className="text-gray-400 text-base">In order to enjoy the full range of services we offer, please verify your email.</p>
  </div>
  if (clientTrackingPixel.isSuccess && companies.length === 0)
  return <div className="flex flex-row gap-4 p-5">
        <div className="flex-1">
          <Heading icon="code" iconSize={33} title="Have you integrate Insights in your website?" />
          <div className="space-y-3 mt-4">
              <p className="text-gray-400 text-base">Turn anonymous clicks and page views into tangible leads by identifying individuals and companies behind the web visits.</p>
              <p className="text-gray-400 text-base">While some traffic will ultimately stay anonymous, you will find that much of your traffic can be deanonymized to give you valuable insights that translate into strategic marketing decisions.</p>
              <p className="text-gray-400 text-base">De-anonymize the visitors on your website and receive data within hours after placing our tracking pixel.</p>
        </div></div>
        <div className="flex-1 w-full">
            <p className="text-white-500 font-bold text-base mb-2">🚀 Integrate the following code into your website to start tracking visitors</p>
            <CopyBlock
              theme={themeBlock} language="html"  showLineNumbers={false} wrapLongLines
              customStyle={{
                "padding": "8px 10px",
                "fontSize": "15px",
                "overflow": "hidden",
                "textOverflow": "ellipsis",
                "whiteSpace": "nowrap",
                "width": "100%"
              }}
              onCopy={() => toast.success("Your tracking pixel has been successfully copied to the clipboard")}
              text={PixelHelper.generateScript(clientTrackingPixel)}
            />
  </div></div>
  return <div className="InsightsBody"><table>
    <thead>{table.getHeaderGroups().map(headerGroup => (<tr key={headerGroup.id}>
      {headerGroup.headers.map(header => {
        return <th key={header.id} colSpan={header.colSpan} style={{width: header.column.columnDef.meta?.size ?? "auto"}}>
          {!["name", "visitLast"].includes(header.id)
            ? flexRender(header.column.columnDef.header, header.getContext())
            : <div className='flex flex-row items-center gap-2'>
              {header.column.getCanSort() &&
                <div 
                  className={"group cursor-pointer select-none w-max flex"} 
                  {...{onClick: header.column.getToggleSortingHandler()}}
                  style={{margin: ["name"].includes(header.id) ? "0" : "0 auto"}}
                ><div className="flex items-center w-auto gap-2 group-hover:bg-paper-600 group-hover:text-white-500 px-2 py-1 rounded-xl transition">
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{asc: <Tooltip content="Sort Ascending"><Icon name='sort-up' size={16} /></Tooltip>,
                      desc: <Tooltip content="Sort Descending"><Icon name='sort-down' size={16} /></Tooltip>,
                    }[header.column.getIsSorted() as string] ?? null}
                </div></div>
              }
              {header.column.getCanFilter() ? <Filter column={header.column} /> : null}
            </div>}
        </th>
      })}
    </tr>))}</thead>
    <tbody>
     {table.getRowCount() === 0
      // ⭐ Empty State
      ? <tr><td colSpan={columns.length} className='border-b border-[var(--color-paper-400)] border-solid'><div className="flex gap-3 items-center justify-center p-4 py-6">
          <Icon name="search" size={25} />
          <h1 className="text-base">No matching results were found</h1>
        </div></td></tr>
      : <>
      {table.getRowModel().rows.map(row => {
        return <tr key={row.id}><td colSpan={row.getVisibleCells().length}>
          {/* <InsightsRow row={row} /> */}
        </td></tr>
      })}
      </>}
    </tbody></table>
    <Table><tfoot><tr>
      <td><div className="flex items-center gap-1">
        Showing{' '}
        {table.getRowCount() > 0
          ? <strong className='highlight'>
            {(table.getState().pagination.pageIndex * table.getState().pagination.pageSize)+1}
            -
            {(table.getRowCount() < (table.getState().pagination.pageIndex+1) * table.getState().pagination.pageSize)
              ? table.getRowCount() 
              : (table.getState().pagination.pageIndex+1) * table.getState().pagination.pageSize}
          </strong>
          : <strong className='highlight'>0</strong>
        }
        {'of '}
        <strong className='highlight'>{table.getRowCount()}</strong>
      </div></td>
      <td className='text-right'><div className='flex sm:flex-row gap-4 sm:gap-6 justify-end'>
        <div className="flex items-center gap-3">
          <p className='text-gray-300'>Results per page:</p>
          <InputDropDown
            triggerTitle={table.getState().pagination.pageSize}
            menuArray={
              [10, 50, 100].map(pageSize => ({
                title: pageSize,
                onClick: () => table.setPageSize(pageSize),
                isSelected: table.getState().pagination.pageSize === pageSize,
              })) as any
          } />
        </div>
        <div className="pagination">
          <div className={`${!table.getCanPreviousPage() ? `disabled` : ``}`} onClick={()=>table.setPageIndex(0)}><Icon name="angle-left-double" size={16} /></div>
          <div className={`${!table.getCanPreviousPage() ? `disabled` : ``}`} onClick={()=>table.previousPage()}><Icon name="angle-left" size={16} /></div>
          <div className={`${!table.getCanNextPage() ? `disabled` : ``}`} onClick={()=>table.nextPage()}><Icon name="angle-right" size={16} /></div>
          <div className={`${!table.getCanNextPage() ? `disabled` : ``}`} onClick={()=>table.setPageIndex(table.getPageCount() - 1)}><Icon name="angle-right-double" size={16} /></div>
        </div>
      </div></td>
  </tr></tfoot></Table></div>
}
export default InsightsModule
function Filter({ column }: { column: Column<any, unknown> }) {
  const columnFilterValue = column.getFilterValue()
  const { filterVariant } = column.columnDef.meta ?? {}
  if (filterVariant === 'none') return <div className="h-5" />
  return filterVariant === 'range' ? (
    <div className="flex space-x-2">
      {/* See faceted column filters example for min max values functionality */}
      <DebouncedInput
        type="number"
        value={(columnFilterValue as [number, number])?.[0] ?? ''}
        onChange={value =>
          column.setFilterValue((old: [number, number]) => [value, old?.[1]])
        }
        placeholder={`Min`}
        className="w-24 border shadow rounded"
      />
      <DebouncedInput
        type="number"
        value={(columnFilterValue as [number, number])?.[1] ?? ''}
        onChange={value =>
          column.setFilterValue((old: [number, number]) => [old?.[0], value])
        }
        placeholder={`Max`}
        className="w-24 border shadow rounded"
      />
    </div>
  ) : filterVariant === 'select' ? (
    <select onChange={e => column.setFilterValue(e.target.value)} value={columnFilterValue?.toString()}>
      <option value="">All</option>
      <option value="complicated">complicated</option>
      <option value="relationship">relationship</option>
      <option value="single">single</option>
    </select>
  )
  : <DebouncedInput onChange={value => column.setFilterValue(value)} value={(columnFilterValue ?? '') as string} />
}
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
  } & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  useEffect(() => { setValue(initialValue) }, [initialValue])
  useEffect(() => {
    const timeout = setTimeout(() => {onChange(value)}, debounce)
    return () => clearTimeout(timeout)
  }, [value])

  return <InputField
    width='w-48'
    value={value}
    placeholder="Search" size="tiny" 
    iconName="search"
    onChange={(e: any) => setValue(e.target.value)}
  />
}