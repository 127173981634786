/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Container from "components/atoms/layout/Container/Container";
import PulseBody from "./PulseBody";
import { CopyBlock, a11yDark as themeBlock } from "react-code-blocks";
import "./../SearchMain/index.scss";
import { useAppDispatch, useAppSelector } from "store/hooks";
import constants from "utils/constants";
import Icon from "components/atoms/Icon/Icon";
import PixelHelper from "helpers/PixelHelper";
import { toast } from "react-toastify";
import { RootState } from "store/store";
import Loading from "components/atoms/Loading/Loading";
import { getCompanyPixel } from "store/slices/company.slice";
import { getAllPixelLeads } from "store/slices/insights";
import PulseFilters from "./PulseFilters";
const CONTENT_CLASS = `bg-gray-900 rounded-lg flex flex-col h-[76vh]`;
const PulseModule: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const { data: user, isSuccess: userIsSuccess } = useAppSelector((state) => state.user);
  const clientTrackingPixel = useAppSelector((state: RootState) => state.company.companyPixel);
  const { data: leads, isSuccess: pixelIsSuccess, isLoading: pixelIsLoading } = useAppSelector((state) => state.insights.leads);
  const [isLoadingValidationModule, setIsLoadingValidationModule] = useState(true);
  const [isPulseDataEmpty, setIsPulseDataEmpty] = useState(true);
  // ⭐ Filters
  const [filterJobTitle, setFilterJobTitle] = useState<any[]>([]);
  const [filterSeniority, setFilterSeniority] = useState<any[]>([]);
  const [filterDepartment, setFilterDepartment] = useState<any[]>([]);
  const [filterIndustry, setFilterIndustry] = useState<any[]>([]);
  const [filterCompany, setFilterCompany] = useState<any[]>([]);
  const [filterCompanyHeadcount, setFilterCompanyHeadcount] = useState<any[]>([]);
  const [filterCompanyRevenue, setFilterCompanyRevenue] = useState<any[]>([]);
  const [filterLocationCompany, setFilterLocationCompany] = useState<any[]>([]);
  const [filterLocationPerson, setFilterLocationPerson] = useState<any[]>([]);
  const [filterGender, setFilterGender] = useState<any[]>([]);
  const [filterIncomeRange, setFilterIncomeRange] = useState<any[]>([]);
  // ⭐ Search Inputs
  const [searchPeople, setSearchPeople] = useState<string | number>("");
  const [searchLinkedin, setSearchLinkedin] = useState<any>("");
  useEffect(() => {
    dispatch(getAllPixelLeads({}));
    dispatch(getCompanyPixel());
  }, []);
  useEffect(() => {
    if (!pixelIsSuccess) return;
    if (leads.length > 0) setIsPulseDataEmpty(false);
  }, [pixelIsSuccess, leads]); // eslint-disable-line
  useEffect(() => {
    if (!userIsSuccess || !pixelIsSuccess) return;
    setIsLoadingValidationModule(false);
  }, [userIsSuccess, pixelIsSuccess]); // eslint-disable-line
  return <Container>
    <div className="flex sm:gap-6"><div className="flex basis-1/4"><div className="items-center flex gap-3 text-white-500">
      <Icon name="pulse" size={30} />
      <h1 className="font-bold capitalize text-2xl">{constants.PIXEL_KEYWORD}</h1>
    </div></div></div>
    <div className="flex sm:gap-6">
        <div className={`${CONTENT_CLASS} basis-1/5 gap-3 sm:flex hidden`}>
          <PulseFilters
            searchPeople={searchPeople} setSearchPeople={setSearchPeople}
            searchLinkedin={searchLinkedin} setSearchLinkedin={setSearchLinkedin}
            filterIncomeRange={filterIncomeRange} setFilterIncomeRange={setFilterIncomeRange}
            filterGender={filterGender} setFilterGender={setFilterGender}
            filterJobTitle={filterJobTitle} setFilterJobTitle={setFilterJobTitle}
            filterCompany={filterCompany} setFilterCompany={setFilterCompany}
            filterIndustry={filterIndustry} setFilterIndustry={setFilterIndustry}
            filterLocationPerson={filterLocationPerson} setFilterLocationPerson={setFilterLocationPerson}
            filterLocationCompany={filterLocationCompany} setFilterLocationCompany={setFilterLocationCompany}
            filterSeniority={filterSeniority} setFilterSeniority={setFilterSeniority}
            filterDepartment={filterDepartment} setFilterDepartment={setFilterDepartment}
            filterCompanyHeadcount={filterCompanyHeadcount} setFilterCompanyHeadcount={setFilterCompanyHeadcount}
            filterCompanyRevenue={filterCompanyRevenue} setFilterCompanyRevenue={setFilterCompanyRevenue}
          />
        </div>
        <div className={`SearchMainBody ${CONTENT_CLASS} w-full sm:w-auto sm:basis-4/5`}>
          {/* ⭐ Verifications to ensure the user has access to the Pulse module */}
          {isLoadingValidationModule || pixelIsLoading 
          ? <Loading />
          : !user.clientId ? (
            <div className="text-white-500 text-center flex flex-col gap-3 justify-center items-center p-4 py-12 sm:py-24 w-2/3 m-auto">
              <Icon name="sad" size={70} />
              <h1 className="text-xl mt-4">You have no{" "}<span className="capitalize">{constants.PIXEL_KEYWORD}</span></h1>
              <p className="text-gray-400 text-base">
                Your current subscription status restricts access to{" "}<span className="capitalize">{constants.PIXEL_KEYWORD}</span>.
              </p>
              <p className="text-gray-400 mb-5 text-base">
                Please contact our team by the email <a className="link" href={`mailto:${constants.COMPANY_1_EMAIL_SUPPORT}`}>{constants.COMPANY_1_EMAIL_SUPPORT}</a>. We are here to help you enjoy the full range of services we offer.
              </p>
            </div>
          ) : !user.hasVerifiedEmail ? (
            <div className="text-white-500 text-center flex flex-col gap-3 justify-center items-center p-4 py-12 sm:py-24 w-2/3 m-auto">
              <Icon name="email" size={70} />
              <h1 className="text-xl mt-4">Please verify your email</h1>
              <p className="text-gray-400 text-base">In order to enjoy the full range of services we offer, please verify your email.</p>
            </div>
          ) : isPulseDataEmpty ? (
            <div className="flex flex-col items-center justify-center gap-4 w-2/3 h-full m-auto">
              <div className="bg-primary-500 flex justify-center items-center rounded-lg text-white-500 w-[58px] h-[52px]"><Icon size={37} name="code" /></div>
              <h1 className="font-bold text-xl">Start by setting up a tracking pixel</h1>
              <div className="space-y-3">
                <p className="text-gray-400 text-base">Turn anonymous clicks and page views into tangible leads by identifying individuals and companies behind the web visits.</p>
                <p className="text-gray-400 text-base">
                  While some traffic will ultimately stay anonymous, you will find that much of your traffic can be deanonymized to give you valuable insights that translate into strategic marketing decisions.
                </p>
                <p className="text-gray-400 text-base">De-anonymize the visitors on your website and receive data within hours after placing our tracking pixel.</p>
                <p className="pt-3 text-white-500 text-base font-bold">{`Copy and paste this code into the <HEAD> of every webpage you want to track:`}</p>
              </div>
              <CopyBlock
                theme={themeBlock} language="html" showLineNumbers={false} wrapLongLines
                customStyle={{
                  "padding": "8px 10px",
                  "fontSize": "15px",
                  "overflow": "hidden",
                  "textOverflow": "ellipsis",
                  "whiteSpace": "nowrap",
                  "width": "100%"
                }}
                onCopy={() => toast.success("Your tracking pixel has been successfully copied to the clipboard")}
                text={PixelHelper.generateScript(clientTrackingPixel)}
              />
            </div>
          ) : (
            <PulseBody
              searchPeople={searchPeople}
              setSearchPeople={setSearchPeople}
              searchLinkedin={searchLinkedin}
              filterJobTitle={filterJobTitle}
              filterCompany={filterCompany}
              filterCompanyHeadcount={filterCompanyHeadcount}
              filterCompanyRevenue={filterCompanyRevenue}
              filterIndustry={filterIndustry}
              filterLocationPerson={filterLocationPerson}
              filterLocationCompany={filterLocationCompany}
              filterGender={filterGender}
              filterSeniority={filterSeniority}
              filterDepartment={filterDepartment}
              filterIncomeRange={filterIncomeRange}
            />
          )}
    </div></div>
  </Container>
};
export default PulseModule;
