/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Table, Container, Icon } from "components";
import EntryFormatter from "utils/EntryFormatter";
import { getLists, resetListSlice } from "store/slices/list.slice";
import LeadHistoryBodyBulk from "./BodyBulk";
import LeadHistoryFooter from "./Footer";
import { EnrichmentDTO } from "utils/dtos/enrichmentDTO";
import InsightsModule from "./BodyVisitors/InsightsModule";
const LeadHistoryBulk: React.FC = () => {
  const pagesAvailable = 3,
    isMobile = window.innerWidth <= 810,
    dispatch = useAppDispatch(),
    [view] = useState("bulk"),
    [pageBulk, setPageBulk] = useState<number>(1),
    [pagination, setPagination] = useState(
      EntryFormatter.arrayCreateFromNumber(pagesAvailable)
    ),
    [paginationLast, setPaginationLast] = useState<number>(pagesAvailable),
    [BulkListData, setBulkListData] = useState<EnrichmentDTO[]>([]),
    [size, setSize] = useState<number>(20),
    { errorMessage: errorMessageBulk } = useAppSelector((state) => state.lists.uploadData),
    {
      isLoading: isLoadingBulkList,
      isSuccess: isSuccessBulkList,
      lists: bulkListDataEndpoint,
      isLastPage: isLastPageBulkList,
      numberOfLeads: numberOfLeadsBulkList,
    } = useAppSelector((state) => state.lists),
    handleSetSize = (sizeSelected: number) => {
      if (size === sizeSelected) return;
      setSize(sizeSelected);
      let searchParams: any = {
        size: sizeSelected,
      };
      setPageBulk(1);
      dispatch(resetListSlice());
      dispatch(getLists(searchParams));
    },
    loadPage = (pageSelected: number) => {
      if (view === "bulk") {
        if (pageBulk === pageSelected) return;
        setBulkListData([]);
        dispatch(resetListSlice());
        dispatch(getLists({ page: pageSelected, size }));
        setPageBulk(pageSelected);
      }
    },
    paginationRecalculate = () => {
      let nLeads = 0;
      if (view === "bulk") nLeads = numberOfLeadsBulkList;
      const pagesTotal = Math.ceil(nLeads / size);
      setPaginationLast(pagesTotal);
      if (size * pagesAvailable > nLeads) {
        const newPagesAvailable =
          EntryFormatter.arrayCreateFromNumber(pagesTotal);
        setPagination(newPagesAvailable);
      }
      let newPagination = EntryFormatter.arrayCreateStartingFromNumber(
        1,
        pagesAvailable
      );
      newPagination = newPagination.filter((number) => number <= pagesTotal);
      if (newPagination.length > 0 && newPagination.length < pagesAvailable)
        newPagination.unshift(newPagination[0] - 1);
      setPagination(newPagination);
    };
  // ⭐ set BULK LIST data into the history state
  useEffect(() => {
    if (!isSuccessBulkList) return;
    setBulkListData(bulkListDataEndpoint);
    paginationRecalculate();
  }, [isLoadingBulkList, isSuccessBulkList]);
  // ⭐ Load bulk list endpoint once the bulk list toggle is triggered
  useEffect(() => {
    if (view === "bulk") {
      dispatch(resetListSlice());
      dispatch(getLists({ page: pageBulk, size }));
    }
  }, [dispatch, view]);
  // ⭐ Define LeadHistoryBody based on the view
  let LeadHistoryBody = null;
  switch (view) {
    case "bulk": {
      LeadHistoryBody = (
        <LeadHistoryBodyBulk
          data={BulkListData}
          isLoading={isLoadingBulkList}
          isSuccess={isSuccessBulkList}
          errorMessage={String(errorMessageBulk) || ""}
          isMobile={isMobile}
          tableColumns={4}
        />
      );
      break;
    }
  }
  return (
    <div className="sm:pb-[150px]">
      <Container>
        <div className="flex basis-1/4 text-white gap-2">
          <Icon name="document" size={30} />
          <h1 className="font-bold text-2xl">Exports</h1>
        </div>
        <div className="interface shadow-none overflow-hidden">
          {["single", "bulk"].includes(view) ? (
            <Table>
              {LeadHistoryBody}
              <LeadHistoryFooter
                isLastPage={isLastPageBulkList}
                isLoading={isLoadingBulkList}
                isSuccess={isSuccessBulkList}
                numberOfLeads={numberOfLeadsBulkList}
                page={pageBulk}
                tableColumns={4}
                pagination={pagination}
                paginationLast={paginationLast}
                handleSetSize={handleSetSize}
                loadPage={loadPage}
                size={size}
                isMobile={isMobile}
              />
            </Table>
          ) : (
            <InsightsModule />
          )}
        </div>
      </Container>
    </div>
  );
};
export default LeadHistoryBulk;
