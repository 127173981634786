import Button from "components/atoms/Button/Button";
import Icon from "components/atoms/Icon/Icon";
import InputField from "components/atoms/InputField/InputField";

import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "store/hooks";

import {
  exportContactRevealedByIDs,
  resetExport,
} from "store/slices/data.slice";

import { getPersonalInfo } from "store/slices/user.slice";
import constants from "utils/constants";

interface Props {
  unselectedRows: string[];
  selectedRows: string[];
  setIsModalOpen: (newVal: boolean) => void;
  setSelectedRows: Dispatch<SetStateAction<string[]>>;
  query: string;
  isAllListSelected: boolean;
  setIsAllListSelected?: Dispatch<SetStateAction<boolean>>;
  totalRowsInList: number;
}
const ExportModal = ({
  unselectedRows,
  selectedRows,
  setIsModalOpen,
  setSelectedRows,
  isAllListSelected = false, setIsAllListSelected = () => {},
  query,
  totalRowsInList
}: Props) => {
  const { errorMessage, isLoading, isSuccess } = useAppSelector((state) => state.data.exportLead);
  const [listName, setListName] = useState("");
  const dispatch = useAppDispatch();
  const handleExport = async () => {
    if (listName?.length === 0) { toast.error("Kindly write a list name for your exports"); return; }
    await dispatch(
      exportContactRevealedByIDs({ 
        query,
        isAllLeadsSelected: isAllListSelected,
        leadIds: selectedRows,
        leadIdsToIgnore: unselectedRows,
        listName,
        leadHistoryCount: totalRowsInList
      })
    );
  };
  useEffect(() => {
    if (errorMessage) {
      if (isSuccess) {
        dispatch(getPersonalInfo());
        toast.success(errorMessage ? (errorMessage as string) : "Your list is being processed. It should be ready in about a minute.");
        toast.success("We will send you an email letting you know when your list is ready.");
        setIsModalOpen(false);
        setSelectedRows([]);
        setIsAllListSelected(false);
      } else {
        toast.error((errorMessage as string) || "Something went wrong");
      }
      dispatch(resetExport());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, errorMessage]);
  return (
    <div className="flex flex-col items-center gap-4">
      <h3 className="font-bold text-xl">Export as CSV</h3>
      <p className="text-gray-400 text-base mb-6">
        Please note that by adding new contacts to a list costs 1 enrichment
        credit per lead.
        <br /> Adding previously saved contacts to a list does not cost credits.
      </p>
      <div className="
        bg-primary-500
        flex justify-center items-center
        rounded-lg
        text-white
        w-[58px] h-[58px]
      ">
        <Icon name="document" size={35} />
      </div>
      <p className="text-lg font-bold text-white-400">
        {isAllListSelected ? Number(
            (totalRowsInList > constants.LIMIT_ENRICHMENT_ATTEMPT_PER_TASK ? constants.LIMIT_ENRICHMENT_ATTEMPT_PER_TASK : totalRowsInList) - unselectedRows.length
          ).toLocaleString() : selectedRows.length
        } leads selected
      </p>
      <div className="flex flex-col justify-center gap-6 items-center">
        <InputField
          value={listName}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {setListName(e.target.value);}}
          placeholder="Add a name to your list"
          size="small"
        />
        <Button
          isProcessing={isLoading}
          isProcessingTitle="Processing..."
          onClick={handleExport}
          iconName="download"
          size="sm"
        >
          Save Contacts
        </Button>
      </div>
    </div>
  );
};

export default ExportModal;
