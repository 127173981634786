import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "components/atoms/Menu/Menu";
import Icon from "components/atoms/Icon/Icon";
import { useAppSelector } from "store/hooks";
const Profile: React.FC<any> = ({
  name,
  credits,
  logout,
  unlimitedCredits,
  dropDown = true
}) => {
  const navigate = useNavigate();
  const [anchor, setAnchor]= useState<null | HTMLElement>(null);
  const open = Boolean(anchor);
  const { data:user } = useAppSelector((state) => state.user);
  return (<>
    <div 
      className="flex items-center gap-3 cursor-pointer"
      onClick={(e) => setAnchor(e.currentTarget)}
    >
      <div className="flex flex-col truncate">
        <div className="truncate">
          {name?.name} {name?.lastName}
        </div>
        <div className="flex items-center gap-2 mt-1 text-sm text-gray-400">
          <Icon name="credits" size={18} />
          {unlimitedCredits
            ? "Unlimited credits"
            : credits === 1
              ? `${credits} credit`
              : `${credits || 0} credits`}
        </div>
      </div>
      {dropDown && <Icon name={open ?'chevron-up':'chevron-down'} size={12} />}
    </div>
    {dropDown &&
      <Menu
        items={[
          {title: 'Account', onClick: ()=> navigate("/user/myAccount")},
          {title: 'Billing & Payment', onClick: ()=> navigate("/user/billing")},
          ...(user.isInvitedUser ? [] : [{title: 'Company Team', onClick: () => navigate("/company")}]),
          {title: 'Logout', onClick: ()=> logout()},
        ]}
        isOpen={open}
        anchor={anchor} setAnchor={setAnchor}
      />
    }
  </>)
};

export default Profile;
